import { ITextVariable } from "common/interfaces/text-variable.interface";
import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getTextVariable = (state: IStore) => state.textVariable;

const getTextVariableSelector = () =>
  createSelector([getTextVariable], (textVariable) => textVariable);

export const getTextVariableById = (id: string) =>
  createSelector([getTextVariable], (textVariable) =>
    textVariable.textVariable.filter((text: ITextVariable) => text._id === id)
  );

export const getCount = () =>
  createSelector([getTextVariable], (data) => data.count);

export default getTextVariableSelector;
