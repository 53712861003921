import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/subscriptions.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import DashboardView from "views/DashboardView/DashboardView";
import i18next from "i18next";
import "./i18n";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";
import getSubscriptionSelector, {
  getCount,
} from "redux/selectors/subscription.selector";

const SubscriptionDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getSubscriptionsData = useSelector(getSubscriptionSelector());
  const getSubscriptionCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [actualPage, setActualPage] = useState(0);
  const [tableKeys, setTableKeys] = useState<string[]>([]);

  useEffect(() => {
    dispatch(actions.getSubscriptions(actualPage));
  }, [actualPage, dispatch]);

  useEffect(() => {
    if (getSubscriptionsData.subscriptions.length) {
      const keys = Object.keys(getSubscriptionsData.subscriptions[0]);
      setTableKeys(keys);
    }
  }, [getSubscriptionsData]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const onDeleteSubscriptions = (subscriptionsIds: string[]) => {
    dispatch(actions.onDeleteSubscriptions(subscriptionsIds));
  };

  const editSubscription = (subscriptionId: string) => {
    dispatch(actions.onSelectEditSubscription(subscriptionId));
  };

  const search = () => {
    dispatch(actions.getSubscriptions(actualPage));
  };

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.edited ? (
          <SuccessAlert message={i18next.t("SUBSCRIPTION:EDITED")} />
        ) : (
          <SuccessAlert message={i18next.t("SUBSCRIPTION:DELETED")} />
        ))}
      <DashboardView
        search={search}
        keys={tableKeys}
        data={getSubscriptionsData.subscriptions as Data[]}
        title="Subscriptions"
        onDelete={onDeleteSubscriptions}
        onEdit={editSubscription}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getSubscriptionCount}
      />
    </DashboardLayout>
  );
};
export default SubscriptionDashboardContainer;
