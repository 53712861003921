import { takeLatest, call, all, put } from "redux-saga/effects";
import { IAPIError } from "common/interfaces/api.interface";
import { AnyAction } from "redux";
import * as colorVariableActions from "../actions/color-variable.actions";
import * as constants from "../constants/color-variable.constants";
import { IColorVariable } from "common/interfaces/template.interface";
import {
  getTenColorVariablesData,
  getCount,
  deleteColorVariables,
  editColorVariable,
} from "service/color-variable.service";

function* getColorVariables(action: AnyAction) {
  try {
    const response: IColorVariable[] = yield call(
      getTenColorVariablesData,
      action.page
    );
    yield put(colorVariableActions.getColorVariablesSucceed(response));
  } catch (err) {
    yield put(colorVariableActions.getColorVariablesFailed(err as IAPIError));
  }
}

function* getColorVariableCount() {
  try {
    const count: number = yield call(getCount);
    yield put(colorVariableActions.getColorVariableCountSucceed(count));
  } catch (err) {
    yield put(
      colorVariableActions.getColorVariableCountFailed(err as IAPIError)
    );
  }
}

function* onDeleteColorVariables(action: AnyAction) {
  try {
    const response: IColorVariable[] = yield call(
      deleteColorVariables,
      action.colorVariablesIds
    );
    yield put(colorVariableActions.onDeleteColorVariablesSucceed(response));
  } catch (err) {
    yield put(
      colorVariableActions.onDeleteColorVariablesFailed(err as IAPIError)
    );
  }
}

function* onEditColorVariables(action: AnyAction) {
  try {
    yield call(editColorVariable, action.colorVariable);
    yield put(colorVariableActions.onEditColorVariableSucceed());
  } catch (err) {
    yield put(colorVariableActions.onEditColorVariableFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(
    constants.ON_RETRIEVE_COLOR_VARIABLES_REQUESTED,
    getColorVariables
  ),
  takeLatest(
    constants.ON_RETRIEVE_COLOR_VARIABLES_REQUESTED,
    getColorVariableCount
  ),
  takeLatest(
    constants.ON_DELETE_COLOR_VARIABLES_REQUESTED,
    onDeleteColorVariables
  ),
  takeLatest(constants.ON_EDIT_COLOR_VARIABLE_REQUESTED, onEditColorVariables),
]);
