import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/color-variable.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import DashboardView from "views/DashboardView/DashboardView";
import i18next from "i18next";
import "./i18n";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";
import getColorVariableSelector, {
  getCount,
} from "redux/selectors/color-variable.selector";

const ColorVariableDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getColorVariablesData = useSelector(getColorVariableSelector());
  const getColorVariablesCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [actualPage, setActualPage] = useState(0);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    dispatch(actions.getColorVariables(actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getColorVariablesData.colorVariable.length) {
      const colorVariableKeys = Object.keys(
        getColorVariablesData.colorVariable[0]
      );
      setKeys(colorVariableKeys);
    }
    return () => {
      setKeys([]);
    };
  }, [getColorVariablesData.colorVariable]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const onDeleteColorVariables = (colorVariablesIds: string[]) => {
    dispatch(actions.onDeleteColorVariables(colorVariablesIds));
  };

  const editColorVariables = (colorVariableId: string) => {
    dispatch(actions.onSelectEditColorVariables(colorVariableId));
  };

  const search = (query: string) => {
    dispatch(actions.getColorVariables(actualPage));
  };

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.edited ? (
          <SuccessAlert message={i18next.t("COLOR_VARIABLE:EDITED")} />
        ) : (
          <SuccessAlert message={i18next.t("COLOR_VARIABLE:DELETED")} />
        ))}
      <DashboardView
        search={search}
        keys={keys}
        data={getColorVariablesData.colorVariable as Data[]}
        title="Color variables"
        onDelete={onDeleteColorVariables}
        onEdit={editColorVariables}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getColorVariablesCount}
      />
    </DashboardLayout>
  );
};
export default ColorVariableDashboardContainer;
