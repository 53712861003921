import { IImageVariable } from "common/interfaces/image-variable.interface";
import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getImageVariable = (state: IStore) => state.imageVariable;

const getImageVariableSelector = () =>
  createSelector([getImageVariable], (imageVariable) => imageVariable);

export const getImageVariableById = (id: string) =>
  createSelector([getImageVariable], (imageVariable) =>
    imageVariable.imageVariable.filter(
      (image: IImageVariable) => image._id === id
    )
  );

export const getCount = () =>
  createSelector([getImageVariable], (data) => data.count);

export default getImageVariableSelector;
