import { ITextVariable } from "common/interfaces/text-variable.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "redux/actions/text-variable.actions";
import { getTextVariableById } from "redux/selectors/text-variable.selector";
import EditTextVariableView from "views/editTextVariable/editTextVariable";

const EditTextVariable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getTextVariableData = useSelector(getTextVariableById(id));

  const editTextVariable = (value: ITextVariable) => {
    dispatch(actions.onEditTextVariable(value));
  };

  return (
    <DashboardLayout>
      <EditTextVariableView
        textVariable={getTextVariableData[0]}
        editVariable={editTextVariable}
      />
    </DashboardLayout>
  );
};

export default EditTextVariable;
