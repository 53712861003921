import { IImageVariable } from "common/interfaces/image-variable.interface";
import { IDataLayer } from "common/interfaces/layer.interface";
import { IShareImageVariable } from "common/interfaces/share.interface";
import { IObject } from "helper/types";

export const replaceImages = (
  dataLayer: IDataLayer,
  images: IImageVariable[],
  shareImageVariables?: IShareImageVariable[]
): IDataLayer => {
  const dataCopy = { ...dataLayer };
  const imagesToReplace = images;
  const dataAssets: IObject[] = dataCopy.assets.map((dataAsset) => {
    const data = { ...dataAsset };
    imagesToReplace.forEach((imagesArray: IImageVariable) => {
      if (imagesArray.name === dataAsset.id) {
        const imageVariable =
          shareImageVariables &&
          shareImageVariables.find((img) => img.name === imagesArray.name);
        data.u = null;
        data.p =
          imageVariable && imageVariable.path
            ? imageVariable.path
            : imagesArray.asset.path
            ? imagesArray.asset.path
            : null;
      }
    });
    return data;
  });
  dataCopy.assets = dataAssets;
  return dataCopy;
};
