import { ISubscription } from "common/interfaces/subscription.interface";
import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getSubscription = (state: IStore) => state.subscription;

const getSubscriptionSelector = () =>
  createSelector([getSubscription], (subscription) => subscription);

export const getSubscriptionById = (id: string) =>
  createSelector([getSubscription], (subscriptions) =>
    subscriptions.subscriptions.filter(
      (subscription: ISubscription) => subscription._id === id
    )
  );

export const getCount = () =>
  createSelector([getSubscription], (data) => data.count);

export default getSubscriptionSelector;
