import { IImageAsset } from "common/interfaces/file.interface";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { IDataLayer } from "common/interfaces/layer.interface";
import { IObject } from "helper/types";

const retrieveImages = (
  dataLayer: IDataLayer,
  templateId: string,
  images: IImageAsset[]
): IImageVariable[] => {
  const dataCopy = { ...dataLayer };
  const imageVariables: IImageVariable[] = [];
  dataCopy.assets.forEach((dataAsset: IObject) => {
    if ((dataAsset.id as string).split("_")[0] === "image") {
      images.forEach((image: IImageAsset) => {
        if (
          (dataAsset.id as string).split("_")[1] ===
          image.file.originalname.split("_")[1].split(".")[0]
        ) {
          imageVariables.push({
            name: image.file.originalname.split(".")[0] as string,
            template: {
              _id: templateId,
            },
            asset: { _id: image.file._id },
            _id: "",
            dataPath: null,
            type: image.type,
          });
        }
      });
    }
  });
  return imageVariables;
};

export default retrieveImages;
