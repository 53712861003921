import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
export const InputContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
`;
