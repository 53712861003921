import { AnyAction } from "redux";
import {
  ON_DELETE_CATEGORY_SUCCESS,
  ON_RETRIEVE_CATEGORIES_REQUESTED,
  ON_RETRIEVE_CATEGORIES_SUCCEED,
  ON_RETRIEVE_CATEGORY_COUNT_SUCCEED,
  ON_SUBMIT_CATEGORY_CREATE_FAILED,
  ON_SUBMIT_CATEGORY_CREATE_REQUESTED,
  ON_SUBMIT_CATEGORY_CREATE_SUCCESS,
} from "redux/constants/category.constants";

export interface ICategoryReducerState {
  categories: any;
  loading: boolean;
  count: number;
}

const defaultState: ICategoryReducerState = {
  categories: [],
  loading: false,
  count: 0,
};

const addCategories = (state: ICategoryReducerState, action: AnyAction) => {
  return {
    ...state,
    categories: action.data,
    loading: false,
  };
};

const addCategory = (state: ICategoryReducerState, action: AnyAction) => {
  return {
    ...state,
    categories: [...state.categories, action.data],
    loading: false,
  };
};

const addCategoryCount = (state: ICategoryReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const setLoading = (state: ICategoryReducerState) => {
  return {
    ...state,
    loading: true,
  };
};
const unsetLoading = (state: ICategoryReducerState) => {
  return {
    ...state,
    loading: false,
  };
};

const categoryReducer = (
  state: ICategoryReducerState = defaultState,
  action: AnyAction
): ICategoryReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_CATEGORIES_REQUESTED:
    case ON_SUBMIT_CATEGORY_CREATE_REQUESTED:
      return setLoading(state);
    case ON_RETRIEVE_CATEGORIES_SUCCEED:
    case ON_DELETE_CATEGORY_SUCCESS:
      return addCategories(state, action);
    case ON_SUBMIT_CATEGORY_CREATE_SUCCESS:
      return addCategory(state, action);
    case ON_SUBMIT_CATEGORY_CREATE_FAILED:
      return unsetLoading(state);
    case ON_RETRIEVE_CATEGORY_COUNT_SUCCEED:
      return addCategoryCount(state, action);
    default:
      return state;
  }
};

export default categoryReducer;
