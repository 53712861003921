import React, { FunctionComponent } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { onEditUser } from "redux/actions/user.actions";
import "./i18n";
import i18next from "i18next";
import getErrorSelector from "redux/selectors/error.selector";
import { IError } from "common/interfaces/error.interface";
import { hideErrorNotification } from "redux/actions/error.actions";
import { useParams } from "react-router-dom";
import { getUserById } from "redux/selectors/user.selector";
import EditUserView from "views/EditUserView/EditUserView";
import { IEditUserFormData } from "views/EditUserView/types";
import getSuccessSelector from "redux/selectors/success.selector";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";

const EditUserContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const successSelector = useSelector(getSuccessSelector());
  const getUserData = useSelector(getUserById(id));
  const errorSelector: IError = useSelector(getErrorSelector());

  const onSubmit = (formData: IEditUserFormData) => {
    dispatch(onEditUser(formData, id));
  };

  setTimeout(() => {
    if (errorSelector.error) {
      dispatch(hideErrorNotification());
    }
  }, 3000);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector && (
        <SuccessAlert message={i18next.t("USER_EDIT:EDIT")} />
      )}
      <EditUserView
        userInfo={getUserData[0]}
        onSubmit={onSubmit}
        userError={errorSelector}
      />
    </DashboardLayout>
  );
};

export default EditUserContainer;
