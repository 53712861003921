import { ISubscription } from "common/interfaces/subscription.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "redux/actions/subscriptions.actions";
import { getSubscriptionById } from "redux/selectors/subscription.selector";
import EditSubscriptionView from "views/editSubscription/editSubscription";

const EditSubscription: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getSubscriptionData = useSelector(getSubscriptionById(id));

  const editSubscription = (values: ISubscription) => {
    dispatch(actions.onEditSubscription(values));
  };

  return (
    <DashboardLayout>
      <EditSubscriptionView
        subscription={getSubscriptionData[0]}
        editSubscription={editSubscription}
      />
    </DashboardLayout>
  );
};

export default EditSubscription;
