import { IStore } from "redux/reducers/types";
import { IUser } from "common/interfaces/user.interface";
import { createSelector } from "reselect";

const getUser = (state: IStore) => state.user;

export const getUsersData = () =>
  createSelector([getUser], (data) => {
    return data.users;
  });

const userSelector = () => createSelector([getUser], (user) => user.data);
export default userSelector;

export const getUserEmailToRegister = () =>
  createSelector([getUser], (user) => user.emailToRegister);

export const getUserById = (id: string) =>
  createSelector([getUser], (user) =>
    user.users.filter((user: IUser) => user._id === id)
  );

export const getUserIsLoading = () =>
  createSelector([getUser], (user) => user.isLoading);

export const getUserError = () =>
  createSelector([getUser], (user) => user.error);

export const getUserSucceed = () =>
  createSelector([getUser], (user) => user.succeed);

export const getCount = () => createSelector([getUser], (user) => user.count);
