import { IAPIError } from "common/interfaces/api.interface";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/image-variable.constants";

export const getImageVariables = (page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_IMAGE_VARIABLES_REQUESTED,
  page,
});

export const getImageVariablesSucceed = (
  data: IImageVariable[]
): AnyAction => ({
  type: constants.ON_RETRIEVE_IMAGE_VARIABLES_SUCCEED,
  data,
});

export const getImageVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_IMAGE_VARIABLES_FAILED,
  error,
});

export const onDeleteImageVariables = (
  ImageVariablesIds: string[]
): AnyAction => ({
  type: constants.ON_DELETE_IMAGE_VARIABLES_REQUESTED,
  ImageVariablesIds,
});

export const onDeleteImageVariablesSucceed = (
  data: IImageVariable[]
): AnyAction => ({
  type: constants.ON_DELETE_IMAGE_VARIABLES_SUCCEED,
  data,
});

export const onDeleteImageVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_IMAGE_VARIABLES_FAILED,
  error,
});

export const onSelectEditImageVariables = (
  imageVariableId: string
): AnyAction => ({
  type: constants.ON_SELECT_IMAGE_VARIABLE_EDIT,
  imageVariableId,
});

export const onEditImageVariable = (
  imageVariable: IImageVariable,
  file: FileList
): AnyAction => ({
  type: constants.ON_EDIT_IMAGE_VARIABLE_REQUESTED,
  imageVariable,
  file,
});

export const onEditImageVariableSucceed = (): AnyAction => ({
  type: constants.ON_EDIT_IMAGE_VARIABLE_SUCCEED,
});

export const onEditImageVariableFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_EDIT_IMAGE_VARIABLE_FAILED,
  error,
});

export const getImageVariableCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_IMAGE_VARIABLE_COUNT_SUCCEED,
  count,
});

export const getImageVariableCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_IMAGE_VARIABLE_COUNT_FAILED,
  error,
});
