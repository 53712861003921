import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/category.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import DashboardView from "views/DashboardView/DashboardView";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import i18next from "i18next";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";
import getCategoriesSelector, {
  getCount,
} from "redux/selectors/categories.selector";

const CategoryDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [actualPage, setActualPage] = useState(0);
  const getCategoryData = useSelector(getCategoriesSelector());
  const getCategoriesCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [tableKeys, setTableKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.getCategories(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getCategoryData.categories.length) {
      const keys = Object.keys(getCategoryData.categories[0]);
      setTableKeys(keys);
    }
  }, [getCategoryData]);

  const onClickAdd = () => {
    dispatch(actions.onSelectCategoryAdd());
  };

  const onDelete = (clientIds: string[]) => {
    dispatch(actions.onDeleteCategory(clientIds));
  };

  const search = (query: string) => {
    dispatch(actions.getCategories(query, actualPage));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.created ? (
          <SuccessAlert message={i18next.t("CLIENT:TEXT")} />
        ) : (
          <SuccessAlert message={i18next.t("CLIENT:DELETE")} />
        ))}
      <DashboardView
        search={search}
        data={getCategoryData.categories as Data[]}
        keys={tableKeys}
        onDelete={onDelete}
        title="Category"
        onClickAdd={onClickAdd}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getCategoriesCount}
      />
    </DashboardLayout>
  );
};

export default CategoryDashboardContainer;
