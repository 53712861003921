import { IAPIError } from "common/interfaces/api.interface";
import { ISubscription } from "common/interfaces/subscription.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/subscription.constants";

export const getSubscriptions = (page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_SUBSCRIPTIONS_REQUESTED,
  page,
});

export const getSubscriptionsSucceed = (data: ISubscription[]): AnyAction => ({
  type: constants.ON_RETRIEVE_SUBSCRIPTIONS_SUCCEED,
  data,
});

export const getSubscriptionsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_SUBSCRIPTIONS_FAILED,
  error,
});

export const onDeleteSubscriptions = (
  SubscriptionsIds: string[]
): AnyAction => ({
  type: constants.ON_DELETE_SUBSCRIPTIONS_REQUESTED,
  SubscriptionsIds,
});

export const onDeleteSubscriptionsSucceed = (
  data: ISubscription[]
): AnyAction => ({
  type: constants.ON_DELETE_SUBSCRIPTIONS_SUCCEED,
  data,
});

export const onDeleteSubscriptionsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_SUBSCRIPTIONS_FAILED,
  error,
});

export const onSelectEditSubscription = (
  subscriptionId: string
): AnyAction => ({
  type: constants.ON_SELECT_SUBSCRIPTION_EDIT,
  subscriptionId,
});

export const onEditSubscription = (subscription: ISubscription): AnyAction => ({
  type: constants.ON_EDIT_SUBSCRIPTION_REQUESTED,
  subscription,
});

export const onEditSubscriptionSucceed = (): AnyAction => ({
  type: constants.ON_EDIT_SUBSCRIPTION_SUCCEED,
});

export const onEditSubscriptionFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_EDIT_SUBSCRIPTION_FAILED,
  error,
});

export const getSubscriptionCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_SUBSCRIPTION_COUNT_SUCCEED,
  count,
});

export const getSubscriptionCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_SUBSCRIPTION_COUNT_FAILED,
  error,
});
