import { AnyAction } from "redux";
import * as userConstants from "redux/constants/user.constants";
import * as appConstants from "redux/constants/app.constants";
import * as templateConstants from "redux/constants/template.constants";
import * as assetConstants from "redux/constants/asset.constants";
import * as textVariableConstants from "redux/constants/text-variable.constants";
import * as colorVariableConstants from "redux/constants/color-variable.constants";
import * as imageVariableConstants from "redux/constants/image-variable.constants";
import * as subscriptionConstants from "redux/constants/subscription.constants";
import * as categoryConstants from "redux/constants/category.constants";
import history from "helper/history";
import { getCookie } from "helper/cookies";

const redirectMiddleware =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) =>
  (action: AnyAction): AnyAction => {
    switch (action.type) {
      case userConstants.USER_ON_LOGIN_SUCCEEDED:
        history.push({
          pathname: "/dashboard",
        });
        break;
      case userConstants.ON_CREATE_USER_SUCCEEDED:
        history.push(`/dashboard/users/`);
        break;
      case userConstants.ON_REDIRECT_TO_EDIT_USER_FORM:
        history.push(`/dashboard/users/edit/${action.userId}`);
        break;
      case assetConstants.ON_SELECT_ADD_ASSET:
        history.push(`/dashboard/assets/add/`);
        break;
      case assetConstants.ON_SUBMIT_ASSET_SUCCEED:
        history.push(`/dashboard/assets/`);
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case userConstants.USER_ON_REGISTER_SUCCEEDED:
      case userConstants.ON_LOGOUT_SUCCEEDED:
      case userConstants.ON_CHANGE_PASSWORD_SUCCEEDED:
        history.push("/login");
        break;
      case imageVariableConstants.ON_SELECT_IMAGE_VARIABLE_EDIT:
        history.push(
          `/dashboard/image-variables/edit/${action.imageVariableId}`
        );
        break;
      case textVariableConstants.ON_SELECT_TEXT_VARIABLE_EDIT:
        history.push(`/dashboard/text-variable/edit/${action.textVariableId}`);
        break;
      case colorVariableConstants.ON_SELECT_COLOR_VARIABLE_EDIT:
        history.push(
          `/dashboard/color-variables/edit/${action.colorVariableId}`
        );
        break;
      case subscriptionConstants.ON_SELECT_SUBSCRIPTION_EDIT:
        history.push(`/dashboard/subscriptions/edit/${action.subscriptionId}`);
        break;
      case textVariableConstants.ON_EDIT_TEXT_VARIABLE_SUCCEED:
        history.push(`/dashboard/text-variables/`);
        break;
      case userConstants.ON_INITIALIZE_LOGIN_FAILED:
        history.push("/dashboard");
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
        if (action.restricted && action.user) {
          history.push({
            pathname: "/",
          });
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          history.go(0);
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED:
        if (!getCookie("accessToken")) {
          history.push("/login");
          break;
        }
        break;
      case userConstants.ON_REDIRECT_TO_CREATE_USER_FORM:
        history.push("/dashboard/users/create");
        break;
      case templateConstants.ON_SELECT_TEMPLATE_ADD:
        history.push("/dashboard/templates/create");
        break;
      case categoryConstants.ON_SELECT_CATEGORY_ADD:
        history.push("/dashboard/category/create");
        break;
      case categoryConstants.ON_SUBMIT_CATEGORY_CREATE_SUCCESS:
        history.push("/dashboard/category/");
        break;
      default:
        break;
    }
    return next(action);
  };

export default redirectMiddleware;
