import { Button, Grid, Input, InputLabel } from "@material-ui/core";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import { Container, InputContainer } from "./styles";
import { IProps } from "./types";
import FileInput from "components/FileInput/FileInput";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";

const EditImageVariableView: FunctionComponent<IProps> = (props: IProps) => {
  const [value, setValue] = useState<IImageVariable>(props.imageVariable);
  const [inputFile, setInputFile] = useState<FileList>();

  const onHandleInputChange = (file: FileList) => {
    setInputFile(file);
  };

  useEffect(() => {
    const newValue = { ...props.imageVariable };
    setValue(newValue);
  }, [props.imageVariable]);

  const editVariables = () => {
    if (inputFile) {
      props.editVariable(value, inputFile);
    }
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.name = event.target.value;
    setValue(item);
  };

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.type = event.target.valueAsNumber;
    setValue(item);
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid key={value._id} item xs={12} sm={6} spacing={3}>
          <InputLabel id="templates-select-label">name</InputLabel>
          <Input
            value={value.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeName(event)
            }
          />
          <InputLabel id="templates-select-label">type</InputLabel>
          <Input
            type="number"
            value={value.type}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeType(event)
            }
          />
          <InputLabel id="templates-select-label">image</InputLabel>
          <InputContainer>
            {inputFile?.length && inputFile[0]?.name}
            <FileInput
              accept={ACCEPT_TYPES.images}
              buttonText="Select image"
              onChange={onHandleInputChange}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={editVariables}>
            {i18next.t("EDIT_IMAGE_VARIABLE:BUTTON_TEXT")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditImageVariableView;
