import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
