import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, SwitchProps } from "react-router-dom";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import TemplateForm from "containers/TemplateForm/TemplateForm";
import PublicRoute from "containers/publicRoute/PublicRoute";
import PrivateRoute from "containers/privateRoute/PrivateRoute";
import TemplateDashboardContainer from "containers/TemplatesDashboard/TemplatesDashboard";
import Login from "containers/Login/Login";
import { isUserLoggedSelector } from "redux/selectors/userLogged.selector";
import Register from "containers/Register/Register";
import UserDashboardContainer from "containers/UsersDashboard/UsersDashboard";
import CreateUserContainer from "containers/CreateUser/CreateUser";
import TemplatePreviewContainer from "containers/TemplatePreview/TemplatePreview";
import ForgotPassword from "containers/ForgotPassword/ForgotPassword";
import ChangePassword from "containers/ChangePassword/ChangePassword";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import TextVariableDashboardContainer from "containers/textVariableDashboard/textVariableDashboard";
import EditTextVariable from "containers/editTextVariable/editTextVariable";
import { NODE_ENVIRONMENT } from "configs/configs";
import AssetDashboardContainer from "containers/AssetDashboard/assetDashboard";
import AssetForm from "containers/addAssetForm/AddAssetForm";
import CategoryDashboardContainer from "containers/CategoryDashboardContainer/CategoryDashboardContainer";
import AddCategoryFormContainer from "containers/AddCategoryForm/AddCategoryForm";
import SubscriptionDashboardContainer from "containers/subscriptionDashboard/subscriptionDashboard";
import EditSubscription from "containers/editSubscription/editSubscription";
import ColorVariableDashboardContainer from "containers/colorVariableDashboard/colorVariableDashboard";
import EditColorVariable from "containers/editColorVariable/editColorVariable";
import EditUserContainer from "containers/EditUser/EditUser";
import ImageVariableDashboardContainer from "containers/imageVariableDashboard/imageVariableDashboard";
import EditImageVariable from "containers/editImageVariable/editImageVariable";

const Application: FunctionComponent<SwitchProps> = () => {
  const isUserLogged = !!useSelector(isUserLoggedSelector);

  return (
    <>
      <Switch>
        <PublicRoute exact path="/login" component={Login} restricted={false} />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/change-password/:token"
          component={ChangePassword}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/register/:token"
          component={Register}
          restricted={false}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardLayout}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/category"
          component={CategoryDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/category/create"
          component={AddCategoryFormContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/assets"
          component={AssetDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/assets/add"
          component={AssetForm}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/templates"
          component={TemplateDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/text-variable/edit/:id"
          component={EditTextVariable}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          path="/dashboard/templates/create"
          component={TemplateForm}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/templates/preview/:id"
          component={TemplatePreviewContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/users"
          component={UserDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/text-variables"
          component={TextVariableDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/color-variables"
          component={ColorVariableDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/image-variables"
          component={ImageVariableDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/color-variables/edit/:id"
          component={EditColorVariable}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/image-variables/edit/:id"
          component={EditImageVariable}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/subscriptions"
          component={SubscriptionDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/subscriptions/edit/:id"
          component={EditSubscription}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/users/create"
          component={CreateUserContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/users/edit/:id"
          component={EditUserContainer}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          exact
          path="/404"
          component={NotFoundPage}
          restricted={false}
        />
        {NODE_ENVIRONMENT !== "production" ? (
          <Route path="/">
            <Redirect to="/dashboard" />
          </Route>
        ) : (
          <Route path="/">
            <Redirect to="/404" />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default Application;
