import { IColorVariable } from "common/interfaces/template.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_COLOR_VARIABLES_SUCCEED,
  ON_RETRIEVE_COLOR_VARIABLES_SUCCEED,
  ON_RETRIEVE_COLOR_VARIABLE_COUNT_SUCCEED,
} from "redux/constants/color-variable.constants";

export interface IColorVariableReducerState {
  colorVariable: IColorVariable[];
  count: number;
}

const defaultState: IColorVariableReducerState = {
  colorVariable: [],
  count: 0,
};

const addColorVariables = (
  state: IColorVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    colorVariable: action.data,
  };
};

const addColorVariableCount = (
  state: IColorVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    count: action.count,
  };
};

const colorVariableReducer = (
  state: IColorVariableReducerState = defaultState,
  action: AnyAction
): IColorVariableReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_COLOR_VARIABLES_SUCCEED:
    case ON_DELETE_COLOR_VARIABLES_SUCCEED:
      return addColorVariables(state, action);
    case ON_RETRIEVE_COLOR_VARIABLE_COUNT_SUCCEED:
      return addColorVariableCount(state, action);
    default:
      return state;
  }
};

export default colorVariableReducer;
