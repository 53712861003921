import ICategory from "common/interfaces/category.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React from "react";
import { useDispatch } from "react-redux";
import { onSubmitCategoryCreate } from "redux/actions/category.actions";
import AddCategoryForm from "views/AddCategoryForm/AddCategoryForm";

const AddCategoryFormContainer = () => {
  const dispatch = useDispatch();

  const onSubmitForm = (data: ICategory) => {
    dispatch(onSubmitCategoryCreate(data.name, data.order, data.published));
  };

  return (
    <DashboardLayout>
      <AddCategoryForm onSubmitForm={onSubmitForm} />
    </DashboardLayout>
  );
};

export default AddCategoryFormContainer;
