import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  deleteImageVariables,
  editImageVariable,
  getCount,
  getTenImageVariablesData,
} from "service/image-variable.service";
import { IAPIError } from "common/interfaces/api.interface";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { AnyAction } from "redux";
import * as imageVariableActions from "../actions/image-variable.actions";
import * as constants from "../constants/image-variable.constants";
import { uploadAssetsService } from "service/upload.service";
import { IAssetDashboard } from "common/interfaces/file.interface";

function* getImageVariables(action: AnyAction) {
  try {
    const response: IImageVariable[] = yield call(
      getTenImageVariablesData,
      action.page
    );
    yield put(imageVariableActions.getImageVariablesSucceed(response));
  } catch (err) {
    yield put(imageVariableActions.getImageVariablesFailed(err as IAPIError));
  }
}

function* getImageVariableCount() {
  try {
    const count: number = yield call(getCount);
    yield put(imageVariableActions.getImageVariableCountSucceed(count));
  } catch (err) {
    yield put(
      imageVariableActions.getImageVariableCountFailed(err as IAPIError)
    );
  }
}

function* onDeleteImageVariables(action: AnyAction) {
  try {
    const response: IImageVariable[] = yield call(
      deleteImageVariables,
      action.imageVariablesIds
    );
    yield put(imageVariableActions.onDeleteImageVariablesSucceed(response));
  } catch (err) {
    yield put(
      imageVariableActions.onDeleteImageVariablesFailed(err as IAPIError)
    );
  }
}

function* onEditImageVariables(action: AnyAction) {
  try {
    const asset: IAssetDashboard[] = yield call(
      uploadAssetsService,
      action.file
    );
    const imageVariable = { ...action.imageVariable };
    imageVariable["assetId"] = asset[0]._id;
    yield call(editImageVariable, imageVariable);
    yield put(imageVariableActions.onEditImageVariableSucceed());
  } catch (err) {
    yield put(imageVariableActions.onEditImageVariableFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(
    constants.ON_RETRIEVE_IMAGE_VARIABLES_REQUESTED,
    getImageVariables
  ),
  takeLatest(
    constants.ON_RETRIEVE_IMAGE_VARIABLES_REQUESTED,
    getImageVariableCount
  ),
  takeLatest(
    constants.ON_DELETE_IMAGE_VARIABLES_REQUESTED,
    onDeleteImageVariables
  ),
  takeLatest(constants.ON_EDIT_IMAGE_VARIABLE_REQUESTED, onEditImageVariables),
]);
