import { takeLatest, call, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import retrieveTexts from "helper/retrievers/retrieveTextLayer.helper";
import retrieveImages from "helper/retrievers/retrieveImageLayer.helper";
import { ITemplate } from "common/interfaces/template.interface";
import { IDataLayer, Layer } from "common/interfaces/layer.interface";
import * as services from "service/template.service";
import { IAPIError } from "common/interfaces/api.interface";
import { IAssetDb } from "common/interfaces/file.interface";
import * as templateActions from "../actions/template.actions";
import * as campaignActions from "../actions/campaign.actions";
import * as constants from "../constants/template.constants";
import * as campaignConstants from "../constants/campaign.constants";
import { uploadAssetsService } from "service/upload.service";

export interface ResponseGenerator {
  data: string;
}

function* getTemplate(action: AnyAction) {
  try {
    const response: ITemplate = yield call(
      services.getTemplateService,
      action.templateId
    );
    response.layers = yield call(services.getJsons, response.layers);
    yield put(templateActions.onInitializeTemplateSucceed(response));
  } catch (err) {
    yield put(templateActions.onInitializeTemplateFailed(err as IAPIError));
  }
}

function* getTemplates() {
  try {
    const response: ITemplate[] = yield call(services.getTemplatesService);
    yield put(campaignActions.onGetTemplatesSucceed(response));
  } catch (err) {
    yield put(campaignActions.onGetTemplatesFailed(err as IAPIError));
  }
}

function* getTenTemplates(action: AnyAction) {
  try {
    const response: ITemplate[] = yield call(
      services.getPaginatedTemplatesService,
      action.page
    );
    yield put(templateActions.onGetTemplatesSucceed(response));
  } catch (err) {
    yield put(templateActions.onGetTemplatesFailed(err as IAPIError));
  }
}

function* getTemplateCount() {
  try {
    const count: number = yield call(services.getCount);
    yield put(templateActions.getTemplateCountSucceed(count));
  } catch (err) {
    yield put(templateActions.getTemplateCountFailed(err as IAPIError));
  }
}

function* createTemplate(action: AnyAction) {
  try {
    const thumbnail: IAssetDb[] = yield call(
      uploadAssetsService,
      action.thumbnail
    );
    const emailThumbnail: IAssetDb[] = yield call(
      uploadAssetsService,
      action.emailThumbnail
    );
    const response: ResponseGenerator = yield call(
      services.createTemplateService,
      action.template,
      action.category,
      thumbnail[0]._id,
      emailThumbnail[0]._id
    );

    yield call(services.createScenes, action.scenes, response.data);

    yield call(
      services.createColorVariables,
      action.colorVariables,
      response.data
    );

    const layer: Layer = yield call(
      services.createUniqueLayer,
      action.files,
      response.data
    );

    if (action.images.length) {
      const resultImage: string = layer.asset;
      const assetImage: IDataLayer = yield call(
        services.getAssetPath,
        resultImage
      );

      const imageVariables = retrieveImages(
        assetImage,
        response.data,
        action.images
      );
      yield call(services.createImageVariables, imageVariables);
    }
    const result: string = layer.asset;
    const assetText: IDataLayer = yield call(services.getAssetPath, result);
    const textVariables = retrieveTexts(assetText);

    if (action.audio.assetAudio.length) {
      yield call(services.createAudio, action.audio, response.data);
    }
    if (action.video.length) {
      yield call(services.createVideo, action.video, response.data);
    }

    yield put(
      templateActions.onCreateTemplateAssetsSucceed(
        textVariables,
        response.data
      )
    );
  } catch (err) {
    yield put(templateActions.onCreateTemplateAssetsFailed(err as IAPIError));
  }
}

function* createTemplateSecondStep(action: AnyAction) {
  try {
    yield call(services.createTextVariables, action.texts, action.templateId);
    const responseTemplate: ITemplate = yield call(
      services.getTemplateService,
      action.templateId
    );
    yield put(templateActions.onCreateTemplateSuccess(responseTemplate));
  } catch (err) {
    yield put(templateActions.onCreateTemplateFailed());
  }
}

function* deleteTemplates(action: AnyAction) {
  try {
    const response: ITemplate[] = yield call(
      services.deleteTemplates,
      action.templatesIds
    );
    yield put(templateActions.onDeleteTemplatesSuccess(response));
  } catch (err) {
    yield put(templateActions.onDeleteTemplatesFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_CREATE_TEMPLATE_ASSETS_REQUESTED, createTemplate),
  takeLatest(constants.ON_CREATE_TEMPLATE_REQUESTED, createTemplateSecondStep),
  takeLatest(constants.ON_INITIALIZE_TEMPLATE_REQUESTED, getTemplate),
  takeLatest(constants.ON_DELETE_TEMPLATES_REQUESTED, deleteTemplates),
  takeLatest(campaignConstants.ON_INITIALIZE_CAMPAIGN_FORM, getTemplates),
  takeLatest(
    constants.ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED,
    getTenTemplates
  ),
  takeLatest(
    constants.ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED,
    getTemplateCount
  ),
]);
