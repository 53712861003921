import React, { FunctionComponent, useEffect } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import CreateUserView from "views/CreateUserView/CreateUserView";
import { useDispatch, useSelector } from "react-redux";
import { ICreateUserFormData } from "views/CreateUserView/types";
import {
  onCreateUserRequest,
  onInitializeCreateUser,
} from "redux/actions/user.actions";
import "./i18n";
import getErrorSelector from "redux/selectors/error.selector";
import { IError } from "common/interfaces/error.interface";
import { hideErrorNotification } from "redux/actions/error.actions";

const CreateUserContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const errorSelector: IError = useSelector(getErrorSelector());

  const onSubmit = (formData: ICreateUserFormData) => {
    dispatch(onCreateUserRequest(formData));
  };

  useEffect(() => {
    dispatch(onInitializeCreateUser());
  }, [dispatch]);

  setTimeout(() => {
    if (errorSelector.error) {
      dispatch(hideErrorNotification());
    }
  }, 3000);

  return (
    <DashboardLayout>
      <CreateUserView onSubmit={onSubmit} userError={errorSelector} />
    </DashboardLayout>
  );
};

export default CreateUserContainer;
