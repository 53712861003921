import { IImageVariable } from "common/interfaces/image-variable.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_IMAGE_VARIABLES_SUCCEED,
  ON_RETRIEVE_IMAGE_VARIABLES_SUCCEED,
  ON_RETRIEVE_IMAGE_VARIABLE_COUNT_SUCCEED,
} from "redux/constants/image-variable.constants";

export interface IImageVariableReducerState {
  imageVariable: IImageVariable[];
  count: number;
}

const defaultState: IImageVariableReducerState = {
  imageVariable: [],
  count: 0,
};

const addImageVariables = (
  state: IImageVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    imageVariable: action.data,
  };
};

const addImageVariableCount = (
  state: IImageVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    count: action.count,
  };
};

const imageVariableReducer = (
  state: IImageVariableReducerState = defaultState,
  action: AnyAction
): IImageVariableReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_IMAGE_VARIABLES_SUCCEED:
    case ON_DELETE_IMAGE_VARIABLES_SUCCEED:
      return addImageVariables(state, action);
    case ON_RETRIEVE_IMAGE_VARIABLE_COUNT_SUCCEED:
      return addImageVariableCount(state, action);
    default:
      return state;
  }
};

export default imageVariableReducer;
