import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "UPLOAD_JSONS_FORM", {
  RESET_FILE: "Reset {{layerName}} File",
  RESET_JSON_FILES: "Reset JSON files",
  SUBMIT: "Submit Files",
  TITLE: "Title",
  DURATION: "Duration",
  DESCRIPTION: "Description",
  BACKGROUND_COLOR: "Background color",
  AUDIO_NAME: "Audio name",
  AUDIO: "audio",
  THUMBNAIL: "Thumbnail",
  EMAIL_THUMBNAIL: "Email Thumbnail",
  VIDEO: "video",
  UPLOAD_JSON_FILES: "Upload JSON files",
  SELECTED_JSONS: "Selected files are: ",
  NO_IMAGES_ERROR: "ERROR: Images JSON is required",
  UPLOADED_IMAGES_ERROR:
    "ERROR: Images JSON is uploaded although 'Without images' option was selected",
  JSON_FILES_MESSAGE: "{{layer}} JSON should be named '{{file}}'",
  FRAME_END: "Frame End",
  FRAME_START: "Frame Start",
  ORDER: "Order",
  ADD_SCENE: "Add scene",
  ADD_COLOR_VAR: "Add color variable",
  COLOR_VAR_NAME: "Name (json name, C01, C02, etc)",
  COLOR_VAR_TEXTNAME: "Text Name (Display name in toolbar)",
  COLOR_VAR_TYPE: "Type",
  COLOR_VAR_VALUE: "Value (Color)",
});

i18next.addResources(locales.EN, "LAYER_CONSTANT", {
  TEXT_LAYER: "Text",
  TEXT_BUTTON: "Upload Text Json",
  TEXT_FILE_NAME: "Text.json",
  IMAGES_LAYER: "Images",
  IMAGES_BUTTON: "Upload Images Json",
  IMAGES_FILE_NAME: "Img.json",
  MISC_LAYER: "Miscellaneous",
  MISC_BUTTON: "Upload Miscellaneous Json",
  MISC_FILE_NAME: "Misc.json",
  INTERACTIONS_LAYER: "Interactions",
  INTERACTIONS_BUTTON: "Upload Interactions Json",
  INTERACTIONS_FILE_NAME: "Int.json",
  OBJECTS_LAYER: "Objects",
  OBJECTS_BUTTON: "Upload Objects Json",
  OBJECTS_FILE_NAME: "Obj.json",
});
