import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getSuccess = (state: IStore) => state.success;

const getSuccessSelector = () =>
  createSelector([getSuccess], (success) => success.isShown);

export const getSuccessText = () =>
  createSelector([getSuccess], (success) => success.successText);

export default getSuccessSelector;
