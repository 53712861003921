import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import i18next from "i18next";
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./i18n";
import { BlockPicker, ColorResult } from "react-color";
import {
  IColorVariable,
  IScene,
  Template,
} from "common/interfaces/template.interface";
import { COLORS, PICKER_COLORS } from "common/constants/color.contants";
import {
  Container,
  InputContainer,
  InputColorContainer,
  ResetContainer,
  TextContainer,
  ErrorText,
  Message,
  List,
  ScenesWrapper,
  FileInputContainer,
  SeekerInputContainer,
  AddSceneWrapper,
} from "./styles";
import { IFile, ILayerNames, ILayersFiles, IProps } from "./types";
import { ICategoryDb } from "common/interfaces/category.interface";

const layers: ILayerNames[] = [
  {
    name: i18next.t("LAYER_CONSTANT:TEXT_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:TEXT_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:TEXT_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:IMAGES_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:IMAGES_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:IMAGES_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:MISC_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:MISC_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:MISC_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:INTERACTIONS_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:INTERACTIONS_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:OBJECTS_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:OBJECTS_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:OBJECTS_FILE_NAME"),
  },
];

const UploadJsonsForm: FunctionComponent<IProps> = (props: IProps) => {
  const [inputFiles, setInputFiles] = useState<IFile[]>([]);
  const [inputJsonFile, setInputJsonFile] = useState<File | null>();
  const [assetAudio, setAssetAudio] = useState<File[]>([]);
  const [videoAsset, setVideoAsset] = useState<File[]>([]);
  const [scenes, setScenes] = useState<IScene[]>([]);
  const [colorVariables, setColorVariables] = useState<IColorVariable[]>([]);
  const [thumbnailAsset, setThumbnailAsset] = useState<File[]>([]);
  const [emailThumbnailAsset, setEmailThumbnailAsset] = useState<File[]>([]);
  const [categorySelected, setCategorySelected] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<string>(COLORS.white);
  const [id] = useState(uuidv4());

  const onHandleInputsChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    layers.forEach((layerName: ILayerNames) => {
      const file = files.find(
        (f: File) => f.name.toLowerCase() === layerName.fileName.toLowerCase()
      );
      if (file) {
        setInputFiles((prevState) => [
          ...prevState,
          { name: layerName.name, file },
        ]);
      }
    });
  };

  const uploadNewJson = (file: FileList) => {
    setInputJsonFile(file[0]);
  };

  const onChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategorySelected(event.target.value as string);
  };

  const onChangeAudio = (files: FileList) => {
    const file = Array.from(files);
    setAssetAudio(file);
  };

  const onChangeVideo = (files: FileList) => {
    const file = Array.from(files);
    setVideoAsset(file);
  };

  const onChangeThumbAsset = (files: FileList) => {
    const file = Array.from(files);
    setThumbnailAsset(file);
  };

  const onChangeEmailThumbAsset = (files: FileList) => {
    const file = Array.from(files);
    setEmailThumbnailAsset(file);
  };

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(event.target.valueAsNumber);
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleResetFiles = () => {
    setInputFiles([]);
  };

  const handleResetJsonFile = () => {
    setInputJsonFile(null);
  };

  const handleResetAudioFile = () => {
    setAssetAudio([]);
  };

  const handleResetVideoFile = () => {
    setVideoAsset([]);
  };

  const handleResetThumbnailFile = () => {
    setThumbnailAsset([]);
  };

  const handleResetEmailThumbnailFile = () => {
    setEmailThumbnailAsset([]);
  };

  const jsonValidations = () => {
    if (
      !props.withImages &&
      inputFiles.some(
        (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
      )
    ) {
      return i18next.t("UPLOAD_JSONS_FORM:UPLOADED_IMAGES_ERROR");
    }
    if (
      props.withImages &&
      !inputFiles.some(
        (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
      )
    ) {
      return i18next.t("UPLOAD_JSONS_FORM:NO_IMAGES_ERROR");
    }
    return "";
  };

  const handleUpload = () => {
    const audio = {
      assetAudio,
    };

    const template: Template = {
      title,
      duration,
      description,
      backgroundColor,
      _id: "",
      createdAt: "",
      updatedAt: "",
    };

    if (!inputJsonFile) {
      const files: ILayersFiles = {
        assetText:
          inputFiles.find(
            (inputFile: IFile) =>
              inputFile.name === i18next.t("LAYER_CONSTANT:TEXT_LAYER")
          )?.file || null,
        assetImage:
          inputFiles.find(
            (inputFile: IFile) =>
              inputFile.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
          )?.file || null,
        assetObject:
          inputFiles.find(
            (inputFile: IFile) =>
              inputFile.name === i18next.t("LAYER_CONSTANT:OBJECTS_LAYER")
          )?.file || null,
        assetMiscellaneus:
          inputFiles.find(
            (inputFile: IFile) =>
              inputFile.name === i18next.t("LAYER_CONSTANT:MISC_LAYER")
          )?.file || null,
        assetInteractions:
          inputFiles.find(
            (inputFile: IFile) =>
              inputFile.name === i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER")
          )?.file || null,
      };
      props.onUploadFiles(
        files,
        audio,
        videoAsset,
        template,
        categorySelected,
        thumbnailAsset,
        emailThumbnailAsset,
        scenes,
        colorVariables
      );
    } else {
      props.onUploadFiles(
        inputJsonFile,
        audio,
        videoAsset,
        template,
        categorySelected,
        thumbnailAsset,
        emailThumbnailAsset,
        scenes,
        colorVariables
      );
    }
  };

  const addScene = () => {
    setScenes((prevState) => [
      ...prevState,
      { frameStart: 0, frameEnd: 0, order: 0 },
    ]);
  };

  const addColorVariable = () => {
    setColorVariables((prevState) => [
      ...prevState,
      { name: "", textName: "", type: 0, value: "" },
    ]);
  };

  const handleFrameStartChange = (newValue: number, index: number) => {
    setScenes((prevState) => {
      const newScenes = [...prevState];
      newScenes[index] = {
        frameStart: newValue,
        frameEnd: newScenes[index].frameEnd,
        order: newScenes[index].order,
      };
      return newScenes;
    });
  };

  const handleFrameEndChange = (newValue: number, index: number) => {
    setScenes((prevState) => {
      const newScenes = [...prevState];
      newScenes[index] = {
        frameStart: newScenes[index].frameStart,
        frameEnd: newValue,
        order: newScenes[index].order,
      };
      return newScenes;
    });
  };

  const handleSceneOrderChange = (newValue: number, index: number) => {
    setScenes((prevState) => {
      const newScenes = [...prevState];
      newScenes[index] = {
        frameStart: newScenes[index].frameStart,
        frameEnd: newScenes[index].frameEnd,
        order: newValue,
      };
      return newScenes;
    });
  };

  const handleColorVarNameChange = (newValue: string, index: number) => {
    setColorVariables((prevState) => {
      const newColorVar = [...prevState];
      newColorVar[index] = {
        name: newValue,
        textName: newColorVar[index].textName,
        type: newColorVar[index].type,
        value: newColorVar[index].value,
      };
      return newColorVar;
    });
  };

  const handleColorVarTextNameChange = (newValue: string, index: number) => {
    setColorVariables((prevState) => {
      const newColorVar = [...prevState];
      newColorVar[index] = {
        name: newColorVar[index].name,
        textName: newValue,
        type: newColorVar[index].type,
        value: newColorVar[index].value,
      };
      return newColorVar;
    });
  };

  const handleColorVarTypeChange = (newValue: number, index: number) => {
    setColorVariables((prevState) => {
      const newColorVar = [...prevState];
      newColorVar[index] = {
        name: newColorVar[index].name,
        textName: newColorVar[index].textName,
        type: newValue,
        value: newColorVar[index].value,
      };
      return newColorVar;
    });
  };

  const handleColorVarValueChange = (newValue: string, index: number) => {
    setColorVariables((prevState) => {
      const newColorVar = [...prevState];
      newColorVar[index] = {
        name: newColorVar[index].name,
        textName: newColorVar[index].textName,
        type: newColorVar[index].type,
        value: newValue,
      };
      return newColorVar;
    });
  };

  const handleColorChange = (color: ColorResult) => {
    setBackgroundColor(color.hex);
  };

  return (
    <Container>
      <FileInputContainer>
        <InputContainer key={`title_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:TITLE")}</InputLabel>
          <TextField
            inputProps={{
              maxLength: 255,
            }}
            value={title}
            onChange={onChangeTitle}
            variant="outlined"
          />
        </InputContainer>
        <InputContainer key={`duration_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:DURATION")}</InputLabel>
          <TextField
            variant="outlined"
            type="number"
            value={duration}
            onChange={onChangeDuration}
          />
        </InputContainer>
        <InputContainer key={`description_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:DESCRIPTION")}</InputLabel>
          <TextField
            inputProps={{
              maxLength: 255,
            }}
            value={description}
            onChange={onChangeDescription}
            variant="outlined"
          />
        </InputContainer>
        <InputColorContainer key={`color_${id}`}>
          <InputLabel>
            {i18next.t("UPLOAD_JSONS_FORM:BACKGROUND_COLOR")}
          </InputLabel>
          <BlockPicker
            triangle="hide"
            color={backgroundColor}
            onChangeComplete={handleColorChange}
            colors={PICKER_COLORS}
          />
        </InputColorContainer>
      </FileInputContainer>
      <List>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:TEXT_LAYER"),
            file: i18next.t("LAYER_CONSTANT:TEXT_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:IMAGES_LAYER"),
            file: i18next.t("LAYER_CONSTANT:IMAGES_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:MISC_LAYER"),
            file: i18next.t("LAYER_CONSTANT:MISC_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER"),
            file: i18next.t("LAYER_CONSTANT:INTERACTIONS_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:OBJECTS_LAYER"),
            file: i18next.t("LAYER_CONSTANT:OBJECTS_FILE_NAME"),
          })}
        </Message>
      </List>
      <InputContainer key={`jsons_${id}`}>
        {!inputFiles.length ? (
          <FileInput
            multiple
            accept={ACCEPT_TYPES.jsons}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:UPLOAD_JSON_FILES")}
            onChange={(files) => onHandleInputsChange(files)}
          />
        ) : (
          <>
            <TextContainer>
              {i18next.t("UPLOAD_JSONS_FORM:SELECTED_JSONS") +
                inputFiles.map((inputFile) => inputFile.name).join(" - ")}
              {jsonValidations() && <ErrorText>{jsonValidations()}</ErrorText>}
            </TextContainer>
            <ResetContainer>
              <Button onClick={handleResetFiles}>
                {i18next.t("UPLOAD_JSONS_FORM:RESET_JSON_FILES")}
              </Button>
            </ResetContainer>
          </>
        )}
      </InputContainer>
      <InputContainer key={`json_${id}`}>
        {!inputJsonFile ? (
          <FileInput
            accept={ACCEPT_TYPES.jsons}
            buttonText="individual json"
            onChange={(file) => uploadNewJson(file)}
          />
        ) : (
          <>
            <ResetContainer>
              <Button onClick={handleResetJsonFile}>
                {i18next.t("UPLOAD_JSONS_FORM:RESET_JSON_FILES")}
              </Button>
            </ResetContainer>
          </>
        )}
      </InputContainer>

      <InputContainer key={`thumbnail_${id}`}>
        {thumbnailAsset.length ? (
          <ResetContainer>
            <Button onClick={handleResetThumbnailFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:THUMBNAIL"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.images}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:THUMBNAIL")}
            onChange={(event) => onChangeThumbAsset(event)}
          />
        )}
      </InputContainer>
      <InputContainer key={`emailThumbnail_${id}`}>
        {emailThumbnailAsset.length ? (
          <ResetContainer>
            <Button onClick={handleResetEmailThumbnailFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:EMAIL_THUMBNAIL"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.svgImages}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:EMAIL_THUMBNAIL")}
            onChange={(event) => onChangeEmailThumbAsset(event)}
          />
        )}
      </InputContainer>
      <InputContainer key={`audio_${id}`}>
        {assetAudio.length ? (
          <ResetContainer>
            <Button onClick={handleResetAudioFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:AUDIO"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.audio}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:AUDIO")}
            onChange={(event) => onChangeAudio(event)}
          />
        )}
      </InputContainer>
      <InputContainer key={`video_${id}`}>
        {videoAsset.length ? (
          <ResetContainer>
            <Button onClick={handleResetVideoFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:VIDEO"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.video}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:VIDEO")}
            onChange={(event) => onChangeVideo(event)}
          />
        )}
      </InputContainer>
      <FormControl fullWidth style={{ marginBottom: "30px" }}>
        <InputLabel>Category</InputLabel>
        <Select
          value={categorySelected}
          label="Age"
          onChange={onChangeCategory}
        >
          {props.categories.map((category: ICategoryDb) => (
            <MenuItem key={category._id} value={category._id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ScenesWrapper>
        <div>
          {scenes.map((scene, index) => {
            return (
              <FileInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:FRAME_START")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={scene.frameStart}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFrameStartChange(
                        parseInt(event.target.value, 10),
                        index
                      )
                    }
                  />
                </SeekerInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:FRAME_END")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={scene.frameEnd}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFrameEndChange(
                        parseInt(event.target.value, 10),
                        index
                      )
                    }
                  />
                </SeekerInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:ORDER")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={scene.order}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleSceneOrderChange(
                        parseInt(event.target.value, 10),
                        index
                      )
                    }
                  />
                </SeekerInputContainer>
              </FileInputContainer>
            );
          })}
        </div>
        <AddSceneWrapper>
          <Button onClick={addScene}>
            {i18next.t("UPLOAD_JSONS_FORM:ADD_SCENE")}
          </Button>
        </AddSceneWrapper>
      </ScenesWrapper>
      <ScenesWrapper>
        <div>
          {colorVariables.map((colorVariable, index) => {
            return (
              <FileInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:COLOR_VAR_NAME")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="text"
                    value={colorVariable.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleColorVarNameChange(event.target.value, index)
                    }
                  />
                </SeekerInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:COLOR_VAR_TEXTNAME")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="text"
                    value={colorVariable.textName}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleColorVarTextNameChange(event.target.value, index)
                    }
                  />
                </SeekerInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:COLOR_VAR_TYPE")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={colorVariable.type}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleColorVarTypeChange(
                        parseInt(event.target.value, 10),
                        index
                      )
                    }
                  />
                </SeekerInputContainer>
                <SeekerInputContainer>
                  <InputLabel>
                    {i18next.t("UPLOAD_JSONS_FORM:COLOR_VAR_VALUE")}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="text"
                    value={colorVariable.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleColorVarValueChange(event.target.value, index)
                    }
                  />
                </SeekerInputContainer>
              </FileInputContainer>
            );
          })}
        </div>
        <AddSceneWrapper>
          <Button onClick={addColorVariable}>
            {i18next.t("UPLOAD_JSONS_FORM:ADD_COLOR_VAR")}
          </Button>
        </AddSceneWrapper>
      </ScenesWrapper>
      {((!!inputFiles.length &&
        !jsonValidations() &&
        inputFiles.some(
          (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:TEXT_LAYER")
        )) ||
        inputJsonFile) &&
        title &&
        description &&
        categorySelected &&
        thumbnailAsset && (
          <Button onClick={handleUpload}>
            {i18next.t("UPLOAD_JSONS_FORM:SUBMIT")}
          </Button>
        )}
    </Container>
  );
};

export default UploadJsonsForm;
