import { Button, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import { Container } from "./styles";
import { IProps } from "./types";
import { ISubscription } from "common/interfaces/subscription.interface";
import { status } from "./enums/status.enum";

const EditSubscriptionView: FunctionComponent<IProps> = (props: IProps) => {
  const [values, setValues] = useState<ISubscription>(props.subscription);

  useEffect(() => {
    const newValues = { ...props.subscription };
    setValues(newValues);
  }, [props.subscription]);

  const editSubscription = () => {
    if (values) {
      props.editSubscription(values);
    }
  };

  const onChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const item = { ...values };
    item.status = event.target.value as string;
    setValues(item);
  };

  const onChangeProductId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const item = { ...values };
    item.productId = event.target.value as number;
    setValues(item);
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid key={values._id} item xs={12} sm={6} spacing={3}>
          <InputLabel id="templates-select-label">Status</InputLabel>
          <Select
            value={values.status}
            label="Change status"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              onChangeStatus(event)
            }
          >
            {status.map((item: string) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
          <InputLabel id="templates-select-label">Prdocut id</InputLabel>
          <Select
            value={values.productId}
            label="Change product id"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              onChangeProductId(event)
            }
          >
            <MenuItem value={0}>Free</MenuItem>
            <MenuItem value={1}>Casic</MenuItem>
            <MenuItem value={2}>Pro</MenuItem>
            <MenuItem value={3}>Premium</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={editSubscription}
          >
            {i18next.t("EDIT_SUBSCRIPTION:BUTTON_TEXT")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditSubscriptionView;
