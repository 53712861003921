import { AxiosResponse } from "axios";
import { get, post, put } from "./api";

export const getAllAssets = async (page: number): Promise<AxiosResponse> => {
  const response = await get(`assets?page=${page}`);
  return response.data;
};

export const getAssetImagePath = async (id: string): Promise<string> => {
  const response = await get(`assets/${id}`);
  return response.data as string;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`assets/get-count`);
  return response.data;
};

export const deleteAssets = async (
  assetsIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    assetsIds,
  };
  const response = await put(`assets/delete/`, data);
  return response.data;
};

export const downloadTC = async (
  downloadText: string
): Promise<AxiosResponse> => {
  const data = {
    downloadText,
  };
  const response = await post(`assets/download/tc`, data);
  return response.data;
};
