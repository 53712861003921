import Button from "components/Button/Button";
import i18next from "i18next";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IText } from "common/interfaces/text-variable.interface";
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import { Container, InputContainer } from "./styles";
import { IProps } from "./types";
import "./i18n";

const SelectTextVariableType: FunctionComponent<IProps> = (props: IProps) => {
  const [values, setValues] = useState<IText[]>([]);

  useEffect(() => {
    const newValues = [...props.textVariables];
    setValues(newValues);
  }, [props.textVariables]);

  const onChangeValue = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const items = [...values];
    items[index].type = event.target.value as number;
    setValues(items);
  };

  const onChangeMaxLengthValue = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const items = [...values];
    items[index].maxLength = event.target.value as number;
    setValues(items);
  };

  return (
    <Container>
      <Typography variant="h6" style={{ margin: "30px" }}>
        {i18next.t("SELECT_CTA:SELECT")}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        {values &&
          values.map((textVariable: IText, index: number) => {
            return (
              <Grid key={textVariable.name} item xs={6}>
                <Typography variant="subtitle1">{textVariable.name}</Typography>
                <Typography variant="subtitle2">
                  {textVariable.value}
                </Typography>
                <InputContainer>
                  <Select
                    type="number"
                    variant="outlined"
                    value={textVariable.type}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      onChangeValue(event, index)
                    }
                  >
                    <MenuItem value={0}>Type 0</MenuItem>
                    <MenuItem value={1}>Type 1</MenuItem>
                    <MenuItem value={2}>Type 2</MenuItem>
                  </Select>
                  <TextField
                    variant="outlined"
                    type="number"
                    label="Set max length"
                    value={textVariable.maxLength}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      onChangeMaxLengthValue(event, index)
                    }
                  />
                </InputContainer>
              </Grid>
            );
          })}
        <Button onClick={() => props.createTemplate(values)}>
          {i18next.t("SELECT_CTA:SUBMIT")}
        </Button>
      </Grid>
    </Container>
  );
};

export default SelectTextVariableType;
