import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Button from "components/Button/Button";
import i18next from "i18next";
import React, { useState } from "react";
import { Container, InputContainer } from "./styles";
import IProps from "./types";

const AddCategoryForm = (props: IProps) => {
  const [categoryName, setCategoryName] = useState("");
  const [order, setOrder] = useState(0);
  const [published, setPublished] = useState("yes");

  const onChangeCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(event.target.value);
  };

  const onChangeOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrder(event.target.valueAsNumber);
  };

  const onChangePublished = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublished(event.target.value);
  };

  const handleSubmit = () => {
    const category = {
      name: categoryName,
      order,
      published: published === "yes" ? true : false,
    };
    props.onSubmitForm(category);
  };

  return (
    <Container>
      <InputContainer>
        <InputLabel>Category name</InputLabel>
        <TextField
          variant="outlined"
          type="text"
          value={categoryName}
          onChange={onChangeCategoryName}
        />
      </InputContainer>
      <FormControl component="fieldset">
        <FormLabel component="legend">Published</FormLabel>
        <RadioGroup
          aria-label="Published"
          defaultValue="yes"
          name="radio-buttons-group"
          onChange={onChangePublished}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <InputContainer>
        <InputLabel>Order</InputLabel>
        <TextField
          variant="outlined"
          type="number"
          value={order}
          onChange={onChangeOrder}
        />
      </InputContainer>
      {categoryName !== "" && (
        <Button onClick={handleSubmit}>
          {i18next.t("UPLOAD_JSONS_FORM:SUBMIT")}
        </Button>
      )}
    </Container>
  );
};

export default AddCategoryForm;
