import { takeLatest, call, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
  deleteCampaigns,
  onCreateCampaign,
  getTenCampaignsService,
  getCount,
} from "service/campaign.service";
import { ICampaign } from "common/interfaces/campaign.interface";
import { IAPIError } from "common/interfaces/api.interface";
import { uploadAssetsService } from "service/upload.service";
import { IImageAsset } from "common/interfaces/file.interface";
import * as campaignActions from "../actions/campaign.actions";
import * as constants from "../constants/campaign.constants";

function* createCampaign(action: AnyAction) {
  try {
    const imageAssetResponse: IImageAsset[] = yield call(uploadAssetsService, [
      action.metadataImage,
    ]);
    const faviconAssetResponse: IImageAsset[] = yield call(
      uploadAssetsService,
      [action.metadataFavicon]
    );
    const response: ICampaign = yield call(
      onCreateCampaign,
      action.templateId,
      action.clientId,
      action.campaignName,
      action.metadataTitle,
      action.metadataDescription,
      imageAssetResponse[0].file._id,
      faviconAssetResponse[0].file._id
    );
    yield put(campaignActions.onCreateCampaignSucceed(response));
  } catch (err) {
    yield put(campaignActions.onCreateCampaignFailed(err as IAPIError));
  }
}

function* getTenCampaigns(action: AnyAction) {
  try {
    const response: ICampaign[] = yield call(
      getTenCampaignsService,
      action.query,
      action.page
    );
    yield put(campaignActions.getCampaignsSucceed(response));
  } catch (err) {
    yield put(campaignActions.getCampaignsFailed(err as IAPIError));
  }
}

function* getCampaignCount() {
  try {
    const count: number = yield call(getCount);
    yield put(campaignActions.getCampaignCountSucceed(count));
  } catch (err) {
    yield put(campaignActions.getCampaignCountFailed(err as IAPIError));
  }
}

function* onDeleteCampaigns(action: AnyAction) {
  try {
    const response: ICampaign[] = yield call(
      deleteCampaigns,
      action.campaignsId
    );
    yield put(campaignActions.deleteCampaignsSucceed(response));
  } catch (err) {
    yield put(campaignActions.deleteCampaignsFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_CREATE_CAMPAIGN_REQUESTED, createCampaign),
  takeLatest(constants.ON_RETRIEVE_CAMPAIGNS_REQUESTED, getTenCampaigns),
  takeLatest(constants.ON_DELETE_CAMPAIGNS_REQUESTED, onDeleteCampaigns),
  takeLatest(constants.ON_RETRIEVE_CAMPAIGNS_REQUESTED, getCampaignCount),
]);
