import { AxiosResponse } from "axios";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { get, put } from "./api";

export const getTenImageVariablesData = async (
  page: number
): Promise<AxiosResponse> => {
  const response = await get(`image-variable?page=${page}`);
  return response.data;
};

export const deleteImageVariables = async (
  imageVariablesIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    imageVariablesIds,
  };
  const response = await put(`image-variable/delete`, data);
  return response.data;
};

export const editImageVariable = async (
  imageVariable: IImageVariable
): Promise<AxiosResponse> => {
  const data = {
    imageVariable,
  };
  const response = await put(`image-variable/edit`, data);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`image-variable/get-count`);
  return response.data;
};
