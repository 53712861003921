import React, { FunctionComponent } from "react";
import i18next from "i18next";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import { IEditUserViewProps } from "./types";
import {
  EditUserContainer,
  EditUserContent,
  EditUserFormContainer,
  Title,
} from "./styles";
import "./i18n";
import EditUserForm from "./components/EditUserForm/EditUserForm";

const EditUserView: FunctionComponent<IEditUserViewProps> = (
  props: IEditUserViewProps
) => {
  const { onSubmit, userError } = props;
  return (
    <EditUserContainer data-testid="edit-user-view">
      <EditUserContent>
        <Title>{i18next.t("EDIT_USER_VIEW:TITLE")}</Title>
        <EditUserFormContainer>
          <EditUserForm user={props.userInfo} onSubmit={onSubmit} />
        </EditUserFormContainer>
        {userError.error && (
          <ErrorAlert message={i18next.t("EDIT_USER_VIEW:ERROR")} />
        )}
      </EditUserContent>
    </EditUserContainer>
  );
};

export default EditUserView;
