import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";

import {
  composeValidators,
  emailValidation,
  requiredValidation,
} from "helper/validations";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { ICreateUserFormProps } from "./types";
import { CustomForm, ButtonContainer } from "./styles";
import "./i18n";

const CreateUserForm: FunctionComponent<ICreateUserFormProps> = ({
  onSubmit,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm data-testid="create-user-form" onSubmit={handleSubmit}>
        <Field
          label={i18next.t("CREATE_USER_FORM:FIRST_NAME")}
          name="firstName"
          render={Input}
          type="text"
          validate={requiredValidation}
        />
        <Field
          label={i18next.t("CREATE_USER_FORM:LAST_NAME")}
          name="lastName"
          render={Input}
          type="text"
          validate={requiredValidation}
        />
        <Field
          label={i18next.t("CREATE_USER_FORM:EMAIL")}
          name="email"
          render={Input}
          type="email"
          validate={composeValidators(requiredValidation, emailValidation)}
        />
        <Field
          label={i18next.t("CREATE_USER_FORM:PASSWORD")}
          name="password"
          render={Input}
          type="password"
          validate={requiredValidation}
        />
        <Field name="role" component="select">
          <option />
          <option value={1}>Admin</option>
          <option value={2}>User</option>
        </Field>
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("CREATE_USER_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default CreateUserForm;
