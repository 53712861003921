import { IColorVariable } from "common/interfaces/template.interface";
import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getColorVariable = (state: IStore) => state.colorVariable;

const getColorVariableSelector = () =>
  createSelector([getColorVariable], (colorVariable) => colorVariable);

export const getColorVariableById = (id: string) =>
  createSelector([getColorVariable], (colorVariable) =>
    colorVariable.colorVariable.filter(
      (color: IColorVariable) => color._id === id
    )
  );

export const getCount = () =>
  createSelector([getColorVariable], (data) => data.count);

export default getColorVariableSelector;
