import { Button, Grid, Input, InputLabel } from "@material-ui/core";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import { Container } from "./styles";
import { IProps } from "./types";
import { IColorVariable } from "common/interfaces/template.interface";

const EditColorVariableView: FunctionComponent<IProps> = (props: IProps) => {
  const [value, setValue] = useState<IColorVariable>(props.colorVariable);

  useEffect(() => {
    const newValue = { ...props.colorVariable };
    setValue(newValue);
  }, [props.colorVariable]);

  const editVariables = () => {
    props.editVariable(value);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.name = event.target.value;
    setValue(item);
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.value = event.target.value;
    setValue(item);
  };

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.type = event.target.valueAsNumber;
    setValue(item);
  };

  const onChangeTextName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = { ...value };
    item.textName = event.target.value;
    setValue(item);
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid key={value._id} item xs={12} sm={6} spacing={3}>
          <InputLabel id="templates-select-label">name</InputLabel>
          <Input
            value={value.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeName(event)
            }
          />
          <InputLabel id="templates-select-label">value</InputLabel>
          <Input
            value={value.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeValue(event)
            }
          />
          <InputLabel id="templates-select-label">type</InputLabel>
          <Input
            type="number"
            value={value.type}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeType(event)
            }
          />
          <InputLabel id="templates-select-label">text name</InputLabel>
          <Input
            type="string"
            value={value.textName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTextName(event)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={editVariables}>
            {i18next.t("EDIT_COLOR_VARIABLE:BUTTON_TEXT")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditColorVariableView;
