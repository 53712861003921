import { all } from "redux-saga/effects";
import template from "./sagas/template.sagas";
import upload from "./sagas/upload.sagas";
import campaign from "./sagas/campaign.sagas";
import user from "./sagas/user.sagas";
import textVariable from "./sagas/text-variable.sagas";
import colorVariable from "./sagas/color-variable.sagas";
import imageVariable from "./sagas/image-variable.sagas";
import asset from "./sagas/asset.sagas";
import category from "./sagas/category.sagas";
import subscription from "./sagas/subscription.sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    template,
    upload,
    campaign,
    user,
    asset,
    textVariable,
    imageVariable,
    category,
    subscription,
    colorVariable,
  ]);
}
