export const ACCEPT_TYPES = {
  all: "/*",
  images: "image/*",
  pngImages: "image/png",
  svgImages: "image/svg+xml",
  audio: "audio/*",
  video: "video/*",
  jsons: "application/JSON",
  csv: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
};
