export const USER_ON_LOGIN_REQUESTED = "USER_ON_LOGIN_REQUESTED";
export const USER_ON_LOGIN_SUCCEEDED = "USER_ON_LOGIN_SUCCEEDED";
export const USER_ON_LOGIN_FAILED = "USER_ON_LOGIN_FAILED";

export const USER_ON_REGISTER_REQUESTED = "USER_ON_REGISTER_REQUESTED";
export const USER_ON_REGISTER_SUCCEEDED = "USER_ON_REGISTER_SUCCEEDED";
export const USER_ON_REGISTER_FAILED = "USER_ON_REGISTER_FAILED";

export const ON_INITIALIZE_REGISTER_REQUESTED =
  "ON_INITIALIZE_REGISTER_REQUESTED";
export const ON_INITIALIZE_REGISTER_SUCCEEDED =
  "ON_INITIALIZE_REGISTER_SUCCEEDED";
export const ON_INITIALIZE_REGISTER_FAILED = "ON_INITIALIZE_REGISTER_FAILED";

export const ON_RETRIEVE_USERS_SUCCEEDED = "ON_RETRIEVE_USERS_SUCCEEDED";
export const ON_RETRIEVE_USERS_FAILED = "ON_RETRIEVE_USERS_FAILED";

export const ON_INITIALIZE_LOGIN_REQUESTED = "ON_INITIALIZE_LOGIN_REQUESTED";
export const ON_INITIALIZE_LOGIN_SUCCEED = "ON_INITIALIZE_LOGIN_SUCCEED";
export const ON_INITIALIZE_LOGIN_FAILED = "ON_INITIALIZE_LOGIN_FAILED";

export const ON_LOGOUT_REQUESTED = "ON_LOGOUT_REQUESTED";
export const ON_LOGOUT_SUCCEEDED = "ON_LOGOUT_SUCCEEDED";

export const ON_REDIRECT_TO_CREATE_USER_FORM =
  "ON_REDIRECT_TO_CREATE_USER_FORM";

export const ON_CREATE_USER_REQUESTED = "ON_CREATE_USER_REQUESTED";
export const ON_CREATE_USER_SUCCEEDED = "ON_CREATE_USER_SUCCEEDED";
export const ON_CREATE_USER_FAILED = "ON_CREATE_USER_FAILED";

export const ON_DELETE_USERS_REQUESTED = "ON_DELETE_USERS_REQUESTED";
export const ON_DELETE_USERS_SUCCESS = "ON_DELETE_USERS_SUCCESS";
export const ON_DELETE_USERS_FAILED = "ON_DELETE_USERS_FAILED";
export const ON_RETRIEVE_USER_COUNT_SUCCEED = "ON_RETRIEVE_USER_COUNT_SUCCEED";
export const ON_RETRIEVE_USER_COUNT_FAILED = "ON_RETRIEVE_USER_COUNT_FAILED";
export const ON_RETRIEVE_USER_REQUESTED = "ON_RETRIEVE_USER_REQUESTED";
export const ON_INITIALIZE_USER_DASHBOARD_REQUESTED =
  "ON_INITIALIZE_USER_DASHBOARD_REQUESTED";
export const ON_RETRIEVE_USER_SUCCEED = "ON_RETRIEVE_USER_SUCCEED";
export const ON_RETRIEVE_USER_FAILED = "ON_RETRIEVE_USER_FAILED";

export const ON_SUBMIT_FORGOT_PASSWORD_REQUEST =
  "ON_SUBMIT_FORGOT_PASSWORD_REQUEST";
export const ON_SUBMIT_FORGOT_PASSWORD_SUCCEEDED =
  "ON_SUBMIT_FORGOT_PASSWORD_SUCCEEDED";
export const ON_SUBMIT_FORGOT_PASSWORD_FAILED =
  "ON_SUBMIT_FORGOT_PASSWORD_FAILED";

export const ON_INITIALIZE_CHANGE_PASSWORD_REQUEST =
  "ON_INITIALIZE_CHANGE_PASSWORD_REQUEST";
export const ON_INITIALIZE_CHANGE_PASSWORD_SUCCEEDED =
  "ON_INITIALIZE_CHANGE_PASSWORD_SUCCEEDED";
export const ON_INITIALIZE_CHANGE_PASSWORD_FAILED =
  "ON_INITIALIZE_CHANGE_PASSWORD_FAILED";

export const ON_CHANGE_PASSWORD_REQUEST = "ON_CHANGE_PASSWORD_REQUEST";
export const ON_CHANGE_PASSWORD_SUCCEEDED = "ON_CHANGE_PASSWORD_SUCCEEDED";
export const ON_CHANGE_PASSWORD_FAILED = "ON_CHANGE_PASSWORD_FAILED";
export const ON_INITIALIZE_CREATE_USER = "ON_INITIALIZE_CREATE_USER";
export const ON_EDIT_USER_REQUESTED = "ON_EDIT_USER_REQUESTED";
export const ON_EDIT_USER_SUCCEED = "ON_EDIT_USER_SUCCEED";
export const ON_EDIT_USER_FAILED = "ON_EDIT_USER_FAILED";
export const ON_REDIRECT_TO_EDIT_USER_FORM = "ON_REDIRECT_TO_EDIT_USER_FORM";
