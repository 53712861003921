export const DASHBOARD_LINKS = [
  { key: "Assets", url: "/dashboard/assets" },
  { key: "Templates", url: "/dashboard/templates" },
  { key: "Users", url: "/dashboard/users" },
  { key: "Text Variables", url: "/dashboard/text-variables" },
  { key: "Color Variables", url: "/dashboard/color-variables" },
  { key: "Image Variables", url: "/dashboard/image-variables" },
  { key: "Categories", url: "/dashboard/category" },
  { key: "Subscriptions", url: "/dashboard/subscriptions" },
];
