import { ISubscription } from "common/interfaces/subscription.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_SUBSCRIPTIONS_SUCCEED,
  ON_RETRIEVE_SUBSCRIPTIONS_SUCCEED,
  ON_RETRIEVE_SUBSCRIPTION_COUNT_SUCCEED,
} from "redux/constants/subscription.constants";

export interface ISubscriptionReducerState {
  subscriptions: ISubscription[];
  count: number;
}

const defaultState: ISubscriptionReducerState = {
  subscriptions: [],
  count: 0,
};

const addSubscriptions = (
  state: ISubscriptionReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    subscriptions: action.data.data,
  };
};

const addSubscriptionCount = (
  state: ISubscriptionReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    count: action.count.data,
  };
};

const subscriptionReducer = (
  state: ISubscriptionReducerState = defaultState,
  action: AnyAction
): ISubscriptionReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_SUBSCRIPTIONS_SUCCEED:
    case ON_DELETE_SUBSCRIPTIONS_SUCCEED:
      return addSubscriptions(state, action);
    case ON_RETRIEVE_SUBSCRIPTION_COUNT_SUCCEED:
      return addSubscriptionCount(state, action);
    default:
      return state;
  }
};

export default subscriptionReducer;
