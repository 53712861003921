import { AxiosResponse } from "axios";
import { ISubscription } from "common/interfaces/subscription.interface";
import { get, put } from "./api";

export const getTenSubscriptionData = async (
  page: number
): Promise<AxiosResponse> => {
  const response = await get(`subscription?page=${page}/`);
  return response.data;
};

export const deleteSubscription = async (
  subscriptionIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    subscriptionIds,
  };
  const response = await put(`subscription/delete`, data);
  return response.data;
};

export const editSubscription = async (
  subscription: ISubscription
): Promise<AxiosResponse> => {
  const response = await put(`subscription/edit`, subscription);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`subscription/get-count`);
  return response.data;
};
