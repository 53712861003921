export const ON_RETRIEVE_COLOR_VARIABLES_REQUESTED =
  "ON_RETRIEVE_COLOR_VARIABLES_REQUESTED";
export const ON_RETRIEVE_COLOR_VARIABLES_SUCCEED =
  "ON_RETRIEVE_COLOR_VARIABLES_SUCCEED";
export const ON_RETRIEVE_COLOR_VARIABLES_FAILED =
  "ON_RETRIEVE_COLOR_VARIABLES_FAILED";
export const ON_DELETE_COLOR_VARIABLES_REQUESTED =
  "ON_DELETE_COLOR_VARIABLES_REQUESTED";
export const ON_DELETE_COLOR_VARIABLES_SUCCEED =
  "ON_DELETE_COLOR_VARIABLES_SUCCEED";
export const ON_DELETE_COLOR_VARIABLES_FAILED =
  "ON_DELETE_COLOR_VARIABLES_FAILED";
export const ON_SELECT_COLOR_VARIABLE_EDIT = "ON_SELECT_COLOR_VARIABLE_EDIT";
export const ON_EDIT_COLOR_VARIABLE_REQUESTED =
  "ON_EDIT_COLOR_VARIABLE_REQUESTED";
export const ON_EDIT_COLOR_VARIABLE_SUCCEED = "ON_EDIT_COLOR_VARIABLE_SUCCEED";
export const ON_EDIT_COLOR_VARIABLE_FAILED = "ON_EDIT_COLOR_VARIABLE_FAILED";
export const ON_RETRIEVE_COLOR_VARIABLE_COUNT_SUCCEED =
  "ON_RETRIEVE_COLOR_VARIABLE_COUNT_SUCCEED";
export const ON_RETRIEVE_COLOR_VARIABLE_COUNT_FAILED =
  "ON_RETRIEVE_COLOR_VARIABLE_COUNT_FAILED";
