import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import "./i18n";
import i18next from "i18next";
import { Checkbox } from "@material-ui/core";
import ImageVariableType from "enums/imageVariable.enum";
import {
  ButtonContainer,
  FormWrapper,
  FileName,
  ErrorMessage,
  InputWrapper,
} from "./styles";
import { IImageFile, IProps } from "./types";

const UploadImagesForm: FunctionComponent<IProps> = (props: IProps) => {
  const [inputFiles, setInputFiles] = useState<IImageFile[]>([]);
  const [invalidImage, setInvalidImage] = useState<boolean[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  useEffect(() => {
    if (inputFiles?.length) {
      const invalidImageBooleans = new Array(inputFiles.length);
      inputFiles.forEach((imageFile: IImageFile, index: number) => {
        invalidImageBooleans[index] = ["img_", "image_"].every(
          (validName) => !imageFile.file.name.toLowerCase().includes(validName)
        );
      });
      setDisableButton(false);
      if (invalidImageBooleans.some(Boolean)) {
        setDisableButton(true);
      }
      setInvalidImage(invalidImageBooleans);
    }
  }, [inputFiles, inputFiles.length]);

  const onHandleInputChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    setInputFiles(
      files.map((file) => {
        return { file, type: ImageVariableType.Still };
      })
    );
  };

  const handleReset = () => {
    setInputFiles([]);
  };

  const handleFileUpload = () => {
    props.onUploadImages(inputFiles);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setInputFiles((prevState) =>
      prevState.map((value, i) => {
        if (index === i) {
          return {
            file: value.file,
            type: event.target.checked
              ? ImageVariableType.Variable
              : ImageVariableType.Still,
          };
        }
        return value;
      })
    );
  };

  return (
    <FormWrapper>
      {!inputFiles?.length && (
        <FileInput
          multiple
          accept={ACCEPT_TYPES.images}
          buttonText="Upload Template Images"
          onChange={onHandleInputChange}
        />
      )}
      {!!inputFiles?.length && (
        <>
          <FileName>{i18next.t("UPLOAD_IMAGES_FORM:NAME_OF_FILES")}</FileName>
          {inputFiles.map((imageFile: IImageFile, index: number) => {
            return (
              <InputWrapper>
                <FileName>{imageFile.file.name}</FileName>
                {invalidImage[index] ? (
                  <ErrorMessage>
                    {i18next.t("UPLOAD_IMAGES_FORM:INVALID_NAME")}
                  </ErrorMessage>
                ) : (
                  <>
                    <Checkbox onChange={(event) => onChange(event, index)} />
                    {i18next.t("UPLOAD_IMAGES_FORM:VARIABLE_IMAGE")}
                  </>
                )}
              </InputWrapper>
            );
          })}
          <ButtonContainer>
            <Button onClick={handleFileUpload} disabled={disableButton}>
              {i18next.t("UPLOAD_IMAGES_FORM:CONFIRM")}
            </Button>
            <Button onClick={handleReset}>
              {i18next.t("UPLOAD_IMAGES_FORM:RESET")}
            </Button>
          </ButtonContainer>
        </>
      )}
    </FormWrapper>
  );
};

export default UploadImagesForm;
