import { IColorVariable } from "common/interfaces/template.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import "./i18n";
import * as actions from "redux/actions/color-variable.actions";
import { getColorVariableById } from "redux/selectors/color-variable.selector";
import EditColorVariableView from "views/editColorVariable/editColorVariable";
import getSuccessSelector from "redux/selectors/success.selector";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";

const EditColorVariable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const successSelector = useSelector(getSuccessSelector());
  const getColorVariableData = useSelector(getColorVariableById(id));

  const editColorVariable = (value: IColorVariable) => {
    dispatch(actions.onEditColorVariable(value));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector && (
        <SuccessAlert message={i18next.t("EDIT_COLOR_VARIABLE:EDIT")} />
      )}
      <EditColorVariableView
        colorVariable={getColorVariableData[0]}
        editVariable={editColorVariable}
      />
    </DashboardLayout>
  );
};

export default EditColorVariable;
