import React, { FunctionComponent } from "react";
import i18next from "i18next";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import { ICreateUserViewProps } from "./types";
import {
  CreateUserContainer,
  CreateUserContent,
  CreateUserFormContainer,
  Title,
} from "./styles";
import "./i18n";
import CreateUserForm from "./components/CreateUserForm/CreateUserForm";

const CreateUserView: FunctionComponent<ICreateUserViewProps> = (
  props: ICreateUserViewProps
) => {
  const { onSubmit, userError } = props;
  return (
    <CreateUserContainer data-testid="create-user-view">
      <CreateUserContent>
        <Title>{i18next.t("CREATE_USER_VIEW:TITLE")}</Title>
        <CreateUserFormContainer>
          <CreateUserForm onSubmit={onSubmit} />
        </CreateUserFormContainer>
        {userError.error && (
          <ErrorAlert message={i18next.t("CREATE_USER_VIEW:ERROR")} />
        )}
      </CreateUserContent>
    </CreateUserContainer>
  );
};

export default CreateUserView;
