import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getCategories = (state: IStore) => state.categories;

const getCategoriesSelector = () =>
  createSelector([getCategories], (categories) => categories);

export const getCategoriesLoadingSelector = () =>
  createSelector([getCategories], (categories) => categories.loading);

export const getCount = () =>
  createSelector([getCategories], (categories) => categories.count);

export default getCategoriesSelector;
