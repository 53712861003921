import { IUser } from "common/interfaces/user.interface";
import { AnyAction } from "redux";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  USER_ON_LOGIN_REQUESTED,
  USER_ON_REGISTER_REQUESTED,
  ON_INITIALIZE_REGISTER_REQUESTED,
} from "redux/constants/user.constants";

import * as userService from "service/user.service";
import * as appActions from "redux/actions/app.actions";
import * as userActions from "redux/actions/user.actions";
import * as appConstants from "redux/constants/app.constants";
import * as userConstants from "redux/constants/user.constants";
import { isUserLoggedSelector } from "redux/selectors/userLogged.selector";
import userSelector from "redux/selectors/user.selector";
import { getCookie, removeUserCookies } from "helper/cookies";
import { IAPIError } from "common/interfaces/api.interface";

function* getUser(): Generator {
  if (getCookie("accessToken")) {
    const data = yield call(userService.initialize);
    return data;
  }
  return null;
}

function* initializePrivateRoute() {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector);
    let user: IUser = yield select(userSelector);
    if (!isUserLogged) {
      user = yield call(getUser);
    }
    if (user !== null) {
      yield put(appActions.initializePrivateRouteSucceded(user));
    }
  } catch (error) {
    yield put(appActions.initializePrivateRouteFailed(error as IAPIError));
  }
}

function* initializePublicRoute(action: AnyAction) {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector);
    let user: IUser = yield select(userSelector);
    if (!isUserLogged) {
      user = yield call(getUser);
    }
    yield put(
      appActions.initializePublicRouteSucceded(user, action.restricted)
    );
  } catch (error) {
    yield put(appActions.initializePublicRouteFailed(error as IAPIError));
  }
}

export function* userLogin(action: AnyAction): Generator {
  try {
    const data = yield call(userService.login, action.formData);
    yield put(userActions.onLoginSucceeded(data as IUser));
  } catch (error) {
    yield put(userActions.onLoginFailed(error as IAPIError));
  }
}

export function* userRegister(action: AnyAction): Generator {
  try {
    yield call(userService.register, action.formData);
    yield put(userActions.onRegisterSucceeded());
  } catch (error) {
    yield put(userActions.onRegisterFailed());
  }
}

export function* registerInitialize(action: AnyAction): Generator {
  try {
    const data = yield call(userService.getUserForRegister, action.token);
    yield put(userActions.onInitializeRegisterSucceeded(data as IUser));
  } catch (error) {
    yield put(userActions.onInitializeRegisterFailed());
  }
}

export function* initializeLogin(): Generator {
  if (getCookie("accessToken")) {
    yield put(userActions.onInitializeLoginFailed());
  } else {
    yield put(userActions.onInitializeLoginSucceed());
  }
}

export function* onLogoutRequest(): Generator {
  removeUserCookies();
  yield put(userActions.onLogoutSucceeded());
}

export function* onCreateUser(action: AnyAction): Generator {
  try {
    const data = yield call(userService.createUser, action.formData);
    yield put(userActions.onCreateUserSucceeded(data as IUser));
  } catch (error) {
    yield put(userActions.onCreateUserFailed(error as IAPIError));
  }
}

export function* onDeleteUsers(action: AnyAction): Generator {
  try {
    const users = yield call(userService.deleteUsers, action.ids);
    yield put(userActions.onDeleteUsersSuccess(users as IUser[]));
  } catch (error) {
    yield put(userActions.onDeleteUsersFailed());
  }
}

function* getUsers(action: AnyAction) {
  try {
    const response: IUser[] = yield call(userService.getAllUsers, action.page);
    yield put(userActions.onGetUsersSucceed(response));
  } catch (err) {
    yield put(userActions.onGetUsersFailed(err as IAPIError));
  }
}

function* getUserCount() {
  try {
    const count: number = yield call(userService.getCount);
    yield put(userActions.getUserCountSucceed(count));
  } catch (err) {
    yield put(userActions.getUserCountFailed(err as IAPIError));
  }
}
function* onSubmitForgotPassword(action: AnyAction) {
  try {
    yield call(userService.submitForgotPassword, action.formData);
    yield put(userActions.onSubmitForgotPasswordSucceeded());
  } catch (error) {
    yield put(userActions.onSubmitForgotPasswordFailed());
  }
}

function* onInitializeChangePassword(action: AnyAction) {
  try {
    yield call(userService.initChangePassword, action.token);
    yield put(userActions.onInitializeChangePasswordSucceeded());
  } catch (error) {
    yield put(userActions.onInitializeChangePasswordFailed());
  }
}

function* onChangePassword(action: AnyAction) {
  try {
    yield call(userService.changePassword, action.token, action.formData);
    yield put(userActions.onChangePasswordSucceeded());
  } catch (error) {
    yield put(userActions.onChangePasswordFailed());
  }
}

function* onEditUser(action: AnyAction) {
  try {
    yield call(userService.editUser, action.formData, action.userId);
    yield put(userActions.onEditUserSucceed());
  } catch (err) {
    yield put(userActions.onEditUserFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(USER_ON_LOGIN_REQUESTED, userLogin),
  takeLatest(USER_ON_REGISTER_REQUESTED, userRegister),
  takeLatest(ON_INITIALIZE_REGISTER_REQUESTED, registerInitialize),
  takeLatest(
    appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED,
    initializePrivateRoute
  ),
  takeLatest(
    appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED,
    initializePublicRoute
  ),
  takeLatest(userConstants.ON_LOGOUT_REQUESTED, onLogoutRequest),
  takeLatest(userConstants.ON_CREATE_USER_REQUESTED, onCreateUser),
  takeLatest(userConstants.ON_EDIT_USER_REQUESTED, onEditUser),
  takeLatest(userConstants.ON_DELETE_USERS_REQUESTED, onDeleteUsers),
  takeLatest(userConstants.ON_INITIALIZE_USER_DASHBOARD_REQUESTED, getUsers),
  takeLatest(
    userConstants.ON_INITIALIZE_USER_DASHBOARD_REQUESTED,
    getUserCount
  ),
  takeLatest(
    userConstants.ON_SUBMIT_FORGOT_PASSWORD_REQUEST,
    onSubmitForgotPassword
  ),
  takeLatest(
    userConstants.ON_INITIALIZE_CHANGE_PASSWORD_REQUEST,
    onInitializeChangePassword
  ),
  takeLatest(userConstants.ON_CHANGE_PASSWORD_REQUEST, onChangePassword),
]);
