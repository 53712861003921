import { IAPIError } from "common/interfaces/api.interface";
import { ICategoryDb } from "common/interfaces/category.interface";
import { AnyAction } from "redux";
import { takeLatest, call, all, put } from "redux-saga/effects";
import { ON_INITIALIZE_TEMPLATE_FORM } from "redux/constants/template.constants";
import * as services from "service/category.service";
import * as categoryActions from "../actions/category.actions";
// import * as campaignConstants from "../constants/campaign.constants";
import * as categoryContants from "../constants/category.constants";

export interface ResponseGenerator {
  data: any;
}

function* getCategories() {
  try {
    const response: ICategoryDb[] = yield call(services.getCategoryService);
    yield put(categoryActions.getCategoriesSucceed(response));
  } catch (err) {
    yield put(categoryActions.getCategoriesFailed(err as IAPIError));
  }
}

function* getPaginatedCategories(action: AnyAction) {
  try {
    const response: ICategoryDb[] = yield call(
      services.getPaginatedCategoriesService,
      action.page
    );
    yield put(categoryActions.getCategoriesSucceed(response));
  } catch (err) {
    yield put(categoryActions.getCategoriesFailed(err as IAPIError));
  }
}

function* getCategoryCount() {
  try {
    const count: number = yield call(services.getCount);
    yield put(categoryActions.getCategoriesCountSucceed(count));
  } catch (err) {
    yield put(categoryActions.getCategoriesCountFailed(err as IAPIError));
  }
}

function* createCategory(action: AnyAction) {
  try {
    const response: ICategoryDb = yield call(
      services.createCategory,
      action.name,
      action.order,
      action.published
    );
    yield put(categoryActions.onSubmitCategoryCreateSuccess(response));
  } catch (err) {
    yield put(categoryActions.onSubmitCategoryCreateFailed(err as IAPIError));
  }
}

function* deleteCategory(action: AnyAction) {
  try {
    const response: ICategoryDb[] = yield call(
      services.deleteCategories,
      action.clientIds
    );
    yield put(categoryActions.onDeleteCategorySuccess(response));
  } catch (err) {
    yield put(categoryActions.onDeleteCategoryFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(ON_INITIALIZE_TEMPLATE_FORM, getCategories),
  takeLatest(
    categoryContants.ON_RETRIEVE_CATEGORIES_REQUESTED,
    getPaginatedCategories
  ),
  takeLatest(
    categoryContants.ON_RETRIEVE_CATEGORIES_REQUESTED,
    getCategoryCount
  ),
  takeLatest(
    categoryContants.ON_SUBMIT_CATEGORY_CREATE_REQUESTED,
    createCategory
  ),
  takeLatest(categoryContants.ON_DELETE_CATEGORY_REQUESTED, deleteCategory),
]);
