import { AxiosResponse } from "axios";
import { IUser } from "common/interfaces/user.interface";
import { saveUserCookies } from "helper/cookies";
import { IChangePasswordFormData } from "views/ChangePasswordView/types";
import { ICreateUserFormData } from "views/CreateUserView/types";
import { IEditUserFormData } from "views/EditUserView/types";
import { IForgotPasswordFormData } from "views/ForgotPasswordView/types";
import { ILoginFormData } from "views/LoginView/types";
import { IRegisterFormData } from "views/RegisterView/types";
import { get, post, put } from "./api";

export async function login(formData: ILoginFormData): Promise<AxiosResponse> {
  const response = await post("auth/login", formData);
  const cookie = {
    accessToken: response.data.token,
    expiresIn: response.data.expiresIn,
  };
  saveUserCookies(cookie);
  return response.data;
}

export const getAllUsers = async (page: number): Promise<AxiosResponse> => {
  const response = await get(`user/get-all?page=${page}`);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`user/get-count`);
  return response.data;
};

export async function register(
  formData: IRegisterFormData
): Promise<AxiosResponse> {
  const response = await put("user/register", formData);
  return response;
}

export async function getUserForRegister(
  token: string
): Promise<AxiosResponse> {
  const response = await get(`user/register/${token}`);
  return response.data;
}

export async function initialize(): Promise<AxiosResponse> {
  const response = await get("user/profile");
  return response.data;
}

export async function createUser(
  formData: ICreateUserFormData
): Promise<IUser> {
  const response = await post("user/", formData);
  return response.data;
}

export async function deleteUsers(ids: string[]): Promise<AxiosResponse> {
  const response = await put("user/delete", { ids });
  return response.data;
}

export async function submitForgotPassword(
  formData: IForgotPasswordFormData
): Promise<AxiosResponse> {
  const response = await put("user/forgot-password", formData);
  return response.data;
}

export async function initChangePassword(
  token: string
): Promise<AxiosResponse> {
  const response = await get(`user/change-password/${token}`);
  return response.data;
}

export async function changePassword(
  token: string,
  formData: IChangePasswordFormData
): Promise<AxiosResponse> {
  const response = await put(`user/change-password/${token}`, formData);
  return response.data;
}

export async function editUser(
  formData: IEditUserFormData,
  userId: string
): Promise<AxiosResponse> {
  const response = await put(`user/edit/${userId}`, formData);
  return response.data;
}
