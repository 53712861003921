import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getTemplate = (state: IStore) => state.template;

export const getCount = () =>
  createSelector([getTemplate], (data) => data.count);

export const getTexts = () =>
  createSelector([getTemplate], (data) => data.texts);

export const getTemplateId = () =>
  createSelector([getTemplate], (data) => data.templateId);

export const getSuccessAssets = () =>
  createSelector([getTemplate], (data) => data.successAssets);

const getTemplateSelector = () =>
  createSelector([getTemplate], (template) => template);

export default getTemplateSelector;
