import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getAsset = (state: IStore) => state.asset;

const getAssetSelector = () => createSelector([getAsset], (asset) => asset);

export const getPublicAssetSuccessSelector = () =>
  createSelector([getAsset], (asset) => asset.success);

export const getCount = () => createSelector([getAsset], (data) => data.count);

export default getAssetSelector;
