import { ICategoryDb } from "common/interfaces/category.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/category.constants";

export const getCategories = (query: string, page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CATEGORIES_REQUESTED,
  query,
  page,
});

export const getCategoriesSucceed = (data: ICategoryDb[]): AnyAction => ({
  type: constants.ON_RETRIEVE_CATEGORIES_SUCCEED,
  data,
});

export const getCategoriesFailed = (error: any): AnyAction => ({
  type: constants.ON_RETRIEVE_CATEGORIES_FAILED,
  error,
});

export const getCategoriesCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CATEGORY_COUNT_SUCCEED,
  count,
});

export const getCategoriesCountFailed = (error: any): AnyAction => ({
  type: constants.ON_RETRIEVE_CATEGORY_COUNT_FAILED,
  error,
});

export const onSelectCategoryAdd = (): AnyAction => ({
  type: constants.ON_SELECT_CATEGORY_ADD,
});

export const onSubmitCategoryCreate = (
  name: string,
  order: number,
  published: boolean
): AnyAction => ({
  type: constants.ON_SUBMIT_CATEGORY_CREATE_REQUESTED,
  name,
  order,
  published,
});

export const onSubmitCategoryCreateSuccess = (
  data: ICategoryDb
): AnyAction => ({
  type: constants.ON_SUBMIT_CATEGORY_CREATE_SUCCESS,
  data,
});

export const onSubmitCategoryCreateFailed = (error: any): AnyAction => ({
  type: constants.ON_SUBMIT_CATEGORY_CREATE_FAILED,
  error,
});

export const onDeleteCategory = (clientIds: string[]): AnyAction => ({
  type: constants.ON_DELETE_CATEGORY_REQUESTED,
  clientIds,
});

export const onDeleteCategorySuccess = (data: any[]): AnyAction => ({
  type: constants.ON_DELETE_CATEGORY_SUCCESS,
  data,
});

export const onDeleteCategoryFailed = (error: any): AnyAction => ({
  type: constants.ON_DELETE_CATEGORY_FAILED,
  error,
});
