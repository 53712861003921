export const ON_RETRIEVE_CATEGORIES_REQUESTED =
  "ON_RETRIEVE_CATEGORIES_REQUESTED";

export const ON_RETRIEVE_CATEGORIES_SUCCEED = "ON_RETRIEVE_CATEGORIES_SUCCEED";

export const ON_RETRIEVE_CATEGORIES_FAILED = "ON_RETRIEVE_CATEGORIES_FAILED";

export const ON_SELECT_CATEGORY_ADD = "ON_SELECT_CATEGORY_ADD";

export const ON_SUBMIT_CATEGORY_CREATE_REQUESTED =
  "ON_SUBMIT_CATEGORY_CREATE_REQUESTED";

export const ON_SUBMIT_CATEGORY_CREATE_SUCCESS =
  "ON_SUBMIT_CATEGORY_CREATE_SUCCESS";

export const ON_SUBMIT_CATEGORY_CREATE_FAILED =
  "ON_SUBMIT_CATEGORY_CREATE_FAILED";

export const ON_DELETE_CATEGORY_REQUESTED = "ON_DELETE_CATEGORY_REQUESTED";

export const ON_DELETE_CATEGORY_SUCCESS = "ON_DELETE_CATEGORY_SUCCESS";

export const ON_DELETE_CATEGORY_FAILED = "ON_DELETE_CATEGORY_FAILED";

export const ON_RETRIEVE_CATEGORY_COUNT_SUCCEED =
  "ON_RETRIEVE_CATEGORY_COUNT_SUCCEED";

export const ON_RETRIEVE_CATEGORY_COUNT_FAILED =
  "ON_RETRIEVE_CATEGORY_COUNT_FAILED";
