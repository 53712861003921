import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "helper/history";
import assetReducer from "./asset.reducer";
import templateReducer from "./template.reducer";
import userReducer from "./user.reducer";
import errorReducer from "./error.reducer";
import successReducer from "./success.reducer";
import textVariableReducer from "./text-variable.reducer";
import categoryReducer from "./category.reducer";
import subscriptionReducer from "./subscription.reducer";
import colorVariableReducer from "./color-variable.reducer";
import imageVariableReducer from "./image-variable.reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  asset: assetReducer,
  template: templateReducer,
  user: userReducer,
  error: errorReducer,
  success: successReducer,
  textVariable: textVariableReducer,
  colorVariable: colorVariableReducer,
  imageVariable: imageVariableReducer,
  categories: categoryReducer,
  subscription: subscriptionReducer,
});

export default rootReducer;
