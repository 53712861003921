export const ON_RETRIEVE_SUBSCRIPTIONS_REQUESTED =
  "ON_RETRIEVE_SUBSCRIPTIONS_REQUESTED";
export const ON_RETRIEVE_SUBSCRIPTIONS_SUCCEED =
  "ON_RETRIEVE_SUBSCRIPTIONS_SUCCEED";
export const ON_RETRIEVE_SUBSCRIPTIONS_FAILED =
  "ON_RETRIEVE_SUBSCRIPTIONS_FAILED";
export const ON_DELETE_SUBSCRIPTIONS_REQUESTED =
  "ON_DELETE_SUBSCRIPTIONS_REQUESTED";
export const ON_DELETE_SUBSCRIPTIONS_SUCCEED =
  "ON_DELETE_SUBSCRIPTIONS_SUCCEED";
export const ON_DELETE_SUBSCRIPTIONS_FAILED = "ON_DELETE_SUBSCRIPTIONS_FAILED";
export const ON_SELECT_SUBSCRIPTION_EDIT = "ON_SELECT_SUBSCRIPTION_EDIT";
export const ON_EDIT_SUBSCRIPTION_REQUESTED = "ON_EDIT_SUBSCRIPTION_REQUESTED";
export const ON_EDIT_SUBSCRIPTION_SUCCEED = "ON_EDIT_SUBSCRIPTION_SUCCEED";
export const ON_EDIT_SUBSCRIPTION_FAILED = "ON_EDIT_SUBSCRIPTION_FAILED";
export const ON_RETRIEVE_SUBSCRIPTION_COUNT_SUCCEED =
  "ON_RETRIEVE_SUBSCRIPTION_COUNT_SUCCEED";
export const ON_RETRIEVE_SUBSCRIPTION_COUNT_FAILED =
  "ON_RETRIEVE_SUBSCRIPTION_COUNT_FAILED";
