import { AxiosResponse } from "axios";
import { get, post, put } from "./api";

export const getCategoryService = async (): Promise<AxiosResponse> => {
  const response = await get(`categories/`);
  return response.data;
};

export const getPaginatedCategoriesService = async (
  page: number
): Promise<AxiosResponse> => {
  const response = await get(`categories?page=${page}/`);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`categories/get-count`);
  return response.data;
};

export const createCategory = async (
  name: string,
  order: number,
  published: boolean
): Promise<AxiosResponse> => {
  const response = await post(`categories/`, { name, order, published });
  return response.data;
};

export const deleteCategories = async (
  categoriesIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    categoriesIds,
  };
  const response = await put(`categories/delete`, data);
  return response.data;
};
