import { ILayer } from "common/interfaces/layer.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import { replaceVariables } from "helper/get_json_layer.helper";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as templateActions from "redux/actions/template.actions";
import getTemplateSelector from "redux/selectors/template.selector";
import Player from "views/Player/Player";

const TemplatePreviewContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getTemplateData = useSelector(getTemplateSelector());
  const [layersData, setLayersData] = useState<ILayer[]>([]);

  useEffect(() => {
    dispatch(templateActions.onInitializeTemplate(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getTemplateData.template && !!getTemplateData.template.layers.length) {
      replaceVariables(
        getTemplateData.template,
        setLayersData,
        getTemplateData.template.layers,
        getTemplateData.template.textVariables
      );
    }
  }, [getTemplateData.template, getTemplateData.template.layers]);

  const onClickEndCta = () => {
    window.open("/", "_blank");
  };
  return (
    <DashboardLayout>
      {!!getTemplateData.template.layers.length && (
        <Player
          isSharePlayer={false}
          layers={layersData}
          textVariables={getTemplateData.template.textVariables}
          audio={getTemplateData.template.audio?.dataPath}
          backgroundColor={getTemplateData.template.backgroundColor}
          videoPath={getTemplateData.template.video?.dataPath}
          onClickEndCta={onClickEndCta}
          duration={getTemplateData.template.duration}
        />
      )}
    </DashboardLayout>
  );
};

export default TemplatePreviewContainer;
