import { takeLatest, call, all, put } from "redux-saga/effects";
import { IAPIError } from "common/interfaces/api.interface";
import { AnyAction } from "redux";
import * as subscriptionActions from "../actions/subscriptions.actions";
import * as constants from "../constants/subscription.constants";
import { ISubscription } from "common/interfaces/subscription.interface";
import {
  deleteSubscription,
  editSubscription,
  getCount,
  getTenSubscriptionData,
} from "service/subscription.service";

function* getSubscriptions(action: AnyAction) {
  try {
    const response: ISubscription[] = yield call(
      getTenSubscriptionData,
      action.page
    );
    yield put(subscriptionActions.getSubscriptionsSucceed(response));
  } catch (err) {
    yield put(subscriptionActions.getSubscriptionsFailed(err as IAPIError));
  }
}

function* getSubscriptionCount() {
  try {
    const count: number = yield call(getCount);
    yield put(subscriptionActions.getSubscriptionCountSucceed(count));
  } catch (err) {
    yield put(subscriptionActions.getSubscriptionCountFailed(err as IAPIError));
  }
}

function* onDeleteSubscriptions(action: AnyAction) {
  try {
    const response: ISubscription[] = yield call(
      deleteSubscription,
      action.textVariablesIds
    );
    yield put(subscriptionActions.onDeleteSubscriptionsSucceed(response));
  } catch (err) {
    yield put(
      subscriptionActions.onDeleteSubscriptionsFailed(err as IAPIError)
    );
  }
}

function* onEditSubscription(action: AnyAction) {
  try {
    yield call(editSubscription, action.subscription);
    yield put(subscriptionActions.onEditSubscriptionSucceed());
  } catch (err) {
    yield put(subscriptionActions.onEditSubscriptionFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_RETRIEVE_SUBSCRIPTIONS_REQUESTED, getSubscriptions),
  takeLatest(
    constants.ON_RETRIEVE_SUBSCRIPTIONS_REQUESTED,
    getSubscriptionCount
  ),
  takeLatest(
    constants.ON_DELETE_SUBSCRIPTIONS_REQUESTED,
    onDeleteSubscriptions
  ),
  takeLatest(constants.ON_EDIT_SUBSCRIPTION_REQUESTED, onEditSubscription),
]);
