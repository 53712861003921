import { IAPIError } from "common/interfaces/api.interface";
import { IAsset } from "common/interfaces/file.interface";
import {
  IAudioAsset,
  IColorVariable,
  IScene,
  ITemplate,
  Template,
} from "common/interfaces/template.interface";
import { IText } from "common/interfaces/text-variable.interface";
import { AnyAction } from "redux";
import { ILayersFiles } from "views/TemplateForm/components/components/UploadJsonsForm/types";
import * as constants from "../constants/template.constants";

export const onInitializeTemplate = (templateId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATE_REQUESTED,
  templateId,
});

export const onInitializeTemplateSucceed = (
  template: ITemplate
): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATE_SUCCEED,
  template,
});

export const onInitializeTemplateFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATE_FAILED,
  error,
});

export const onCreateTemplateAssets = (
  files: ILayersFiles | File,
  images: IAsset[],
  audio: IAudioAsset,
  video: File[],
  template: Template,
  category: string,
  thumbnail: File[],
  emailThumbnail: File[],
  scenes: IScene[],
  colorVariables: IColorVariable[]
): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_ASSETS_REQUESTED,
  files,
  images,
  audio,
  video,
  template,
  category,
  thumbnail,
  emailThumbnail,
  scenes,
  colorVariables,
});

export const onCreateTemplateAssetsSucceed = (
  texts: IText[],
  templateId: string
): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_ASSETS_SUCCESS,
  texts,
  templateId,
});

export const onCreateTemplateAssetsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_ASSETS_FAILED,
  error,
});

export const onCreateTemplate = (
  texts: IText[],
  templateId: string
): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_REQUESTED,
  texts,
  templateId,
});

export const onCreateTemplateSuccess = (template: ITemplate): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_SUCCESS,
  template,
});

export const onCreateTemplateFailed = (): AnyAction => ({
  type: constants.ON_CREATE_TEMPLATE_FAILED,
});

export const onInitializeTemplateDashboard = (
  query: string,
  page: number
): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED,
  query,
  page,
});

export const onGetTemplatesSucceed = (data: ITemplate[]): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATES_SUCCEED,
  data,
});

export const onGetTemplatesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATES_FAILED,
  error,
});

export const onSelectAdd = (): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_ADD,
});

export const onInitializeTemplateForm = (): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATE_FORM,
});

export const onDeleteTemplates = (templatesIds: string[]): AnyAction => ({
  type: constants.ON_DELETE_TEMPLATES_REQUESTED,
  templatesIds,
});

export const onDeleteTemplatesSuccess = (data: ITemplate[]): AnyAction => ({
  type: constants.ON_DELETE_TEMPLATES_SUCCESS,
  data,
});

export const onDeleteTemplatesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_TEMPLATES_FAILED,
  error,
});

export const getTemplateCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATE_COUNT_SUCCEED,
  count,
});

export const getTemplateCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATE_COUNT_FAILED,
  error,
});

export const getTemplateCount = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATES_REQUESTED,
  count,
});

export const onChangeSuccessAssets = (): AnyAction => ({
  type: constants.ON_CHANGE_SUCCESS_ASSETS,
});
