import { IAsset } from "common/interfaces/file.interface";
import toBase64 from "helper/convertTo64.helper";
import createFile from "helper/createFileInterface.helper";
import { IImageFile } from "views/TemplateForm/components/components/UploadImagesForm/types";
import { post } from "./api";

export const upload = async (files: IImageFile[]): Promise<IAsset[]> => {
  const fileConverted: string[] = await Promise.all(
    Object.values(files).map(async (imageFile: IImageFile) => {
      const converted: string = (await toBase64(imageFile.file)) as string;
      return converted;
    })
  );
  const data: IAsset[] = Object.values(files).map((file, index) => {
    return createFile(file.file, fileConverted[index]);
  });
  const responses: IAsset[] = await Promise.all(
    Object.values(data).map(async (file: IAsset) => {
      const response = await post("assets/", file);
      return { file: response.data };
    })
  );
  return responses;
};

export const uploadAssetsService = async (files: File[]): Promise<IAsset[]> => {
  const fileConverted: string[] = await Promise.all(
    Object.values(files).map(async (imageFile: File) => {
      const converted: string = (await toBase64(imageFile)) as string;
      return converted;
    })
  );
  const data: IAsset[] = Object.values(files).map((file, index) => {
    return createFile(file, fileConverted[index]);
  });
  const responses: IAsset[] = await Promise.all(
    Object.values(data).map(async (file: IAsset) => {
      const response = await post("assets/", file);
      return response.data;
    })
  );
  return responses;
};

export const uploadPublicAssetService = async (file: File): Promise<IAsset> => {
  const converted: string = (await toBase64(file)) as string;
  const data = createFile(file, converted);
  const response = await post("assets/public", data);
  return response.data;
};

export const uploadPublicAssetServiceTermsAndConditions = async (
  file: File,
  id: string
): Promise<IAsset> => {
  const converted: string = (await toBase64(file)) as string;
  const newFile = createFile(file, converted);
  const data = {
    newFile,
    id,
  };
  const response = await post("assets/tyc/public", data);
  return response.data;
};
