import { IAsset } from "common/interfaces/file.interface";

const createFile = (file: File, data64src: string): IAsset => {
  return {
    file: {
      src: data64src,
      originalname: file.name,
      mimetype: file.type,
      filename: file.name,
      size: file.size,
    },
  };
};

export default createFile;
