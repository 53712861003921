import { AxiosResponse } from "axios";
import { IColorVariable } from "common/interfaces/template.interface";
import { get, put } from "./api";

export const getTenColorVariablesData = async (
  page: number
): Promise<AxiosResponse> => {
  const response = await get(`color-variable?page=${page}`);
  return response.data;
};

export const deleteColorVariables = async (
  colorVariablesIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    colorVariablesIds,
  };
  const response = await put(`color-variable/delete`, data);
  return response.data;
};

export const editColorVariable = async (
  colorVariable: IColorVariable
): Promise<AxiosResponse> => {
  const data = {
    colorVariable,
  };
  const response = await put(`color-variable/edit`, data);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`color-variable/get-count`);
  return response.data;
};
