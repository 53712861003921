import { IAPIError } from "common/interfaces/api.interface";
import { IColorVariable } from "common/interfaces/color-variable.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/color-variable.constants";

export const getColorVariables = (page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_COLOR_VARIABLES_REQUESTED,
  page,
});

export const getColorVariablesSucceed = (
  data: IColorVariable[]
): AnyAction => ({
  type: constants.ON_RETRIEVE_COLOR_VARIABLES_SUCCEED,
  data,
});

export const getColorVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_COLOR_VARIABLES_FAILED,
  error,
});

export const onDeleteColorVariables = (
  colorVariablesIds: string[]
): AnyAction => ({
  type: constants.ON_DELETE_COLOR_VARIABLES_REQUESTED,
  colorVariablesIds,
});

export const onDeleteColorVariablesSucceed = (
  data: IColorVariable[]
): AnyAction => ({
  type: constants.ON_DELETE_COLOR_VARIABLES_SUCCEED,
  data,
});

export const onDeleteColorVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_COLOR_VARIABLES_FAILED,
  error,
});

export const onSelectEditColorVariables = (
  colorVariableId: string
): AnyAction => ({
  type: constants.ON_SELECT_COLOR_VARIABLE_EDIT,
  colorVariableId,
});

export const onEditColorVariable = (
  colorVariable: IColorVariable
): AnyAction => ({
  type: constants.ON_EDIT_COLOR_VARIABLE_REQUESTED,
  colorVariable,
});

export const onEditColorVariableSucceed = (): AnyAction => ({
  type: constants.ON_EDIT_COLOR_VARIABLE_SUCCEED,
});

export const onEditColorVariableFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_EDIT_COLOR_VARIABLE_FAILED,
  error,
});

export const getColorVariableCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_COLOR_VARIABLE_COUNT_SUCCEED,
  count,
});

export const getColorVariableCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_COLOR_VARIABLE_COUNT_FAILED,
  error,
});
